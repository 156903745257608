import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { SideNavi } from '../../layouts/sideNavi'
import ParksWordCloud, { WordData } from '../../components/ParksWordcloud'
import {
  CubeHistoryQuery,
  DeveloperControllerService,
  MemberControllerService,
  MemberRoleQuery,
  PositionControllerService,
  PositionQuery,
  RankingControllerService,
  WordCloudQuery,
  WorkspaceDto,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { CubeHistorySection } from '../../components/cubeSummary/CubeHistorySection'

interface Props {
  developerMode?: boolean
}

export const CubeSummary: FC<Props> = ({ developerMode = false }) => {
  const [workspaces, setWorkspaces] = useState([] as WorkspaceDto[])
  const [workspaceId, setWorkspaceId] = useState<string>()
  const [memberRole, setMemberRole] = useState<MemberRoleQuery>(
    {} as MemberRoleQuery,
  )

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [positionQueries, setPositionQueries] = useState([] as PositionQuery[])
  const [wordCloudQueries, setWordCloudQueries] = useState(
    [] as WordCloudQuery[],
  )
  const [words, setWords] = useState([] as WordData[])

  const [cubeHistoryQueries, setCubeHistoryQueries] = useState(
    [] as CubeHistoryQuery[],
  )
  const [filterDate, setFilterDate] = useState('')
  const [headContents] = useState([
    'No',
    '送られた人',
    '送った人',
    'キューブ名',
    '日時',
  ])

  const handleResize = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const containerHeight = containerRef.current.offsetHeight
      const width = containerWidth * 0.95
      const height = containerHeight > 500 ? 500 : containerHeight

      setDimensions({
        width,
        height,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    MemberControllerService.getMemberRole().then(res => {
      setMemberRole(res)
    })
  }, [])

  useEffect(() => {
    // 初期化
    setPositionQueries([])
    setWordCloudQueries([])
    setWords([])
    setCubeHistoryQueries([])
    setFilterDate('')

    const getWordCloud = developerMode
      ? DeveloperControllerService.getDeveloperWordCloud(workspaceId)
      : RankingControllerService.getWordCloud()
    const getPositions = developerMode
      ? DeveloperControllerService.getDeveloperPositions(workspaceId)
      : PositionControllerService.getPositions()
    const getCubeHistory = developerMode
      ? DeveloperControllerService.getDeveloperCubeHistory(workspaceId)
      : RankingControllerService.getCubeHistory()

    // ワードクラウド
    !(developerMode && !workspaceId) &&
      getWordCloud
        .then(res => {
          setWordCloudQueries(res)
          setWords(
            res.map(wordCloudQuery => ({
              text: wordCloudQuery.cubeName,
              value: wordCloudQuery.count,
            })),
          )
        })
        .catch(apiErrorHandler)
    !(developerMode && !workspaceId) &&
      getPositions.then(setPositionQueries).catch(apiErrorHandler)

    // キューブ履歴
    !(developerMode && !workspaceId) &&
      getCubeHistory.then(setCubeHistoryQueries).catch(apiErrorHandler)

    if (developerMode) {
      DeveloperControllerService.getAllWorkspaces()
        .then(setWorkspaces)
        .catch(apiErrorHandler)
    }
  }, [workspaceId])

  const changePosition = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      setWords(
        wordCloudQueries.map(wordCloudQuery => ({
          text: wordCloudQuery.cubeName,
          value: wordCloudQuery.count,
        })),
      )
      return
    }
    if (e.target.value === 'null') {
      setWords(
        wordCloudQueries
          .filter(wordCloudQuery => wordCloudQuery.positionId === null)
          .map(wordCloudQuery => ({
            text: wordCloudQuery.cubeName,
            value: wordCloudQuery.count,
          })),
      )
      return
    }
    setWords(
      wordCloudQueries
        .filter(
          wordCloudQuery =>
            wordCloudQuery.positionId === Number(e.target.value),
        )
        .map(wordCloudQuery => ({
          text: wordCloudQuery.cubeName,
          value: wordCloudQuery.count,
        })),
    )
  }
  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="main-sidenav">
        <div className="main_sidenav__inner ranking_page__inner">
          <div className="ranking_page__block">
            {developerMode && workspaces.length !== 0 && (
              <div className="ranking_page__content">
                <div className="form">
                  <div className="form__item">
                    <label htmlFor="workspace" className="form__label">
                      ワークスペース
                    </label>
                    <select
                      className="form__select video-setting__select"
                      value={workspaceId}
                      onChange={e => setWorkspaceId(e.target.value)}
                    >
                      <option value="">選択してください</option>
                      {workspaces.map((workspace, index) => (
                        <option key={index} value={workspace.workspaceId}>
                          {workspace.workspaceName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="ranking_page__block">
            <div className="ranking_page__content" ref={containerRef}>
              <h3 className="ranking-page__lead">
                <div>登録されているキューブ</div>
                <div className="ranking-page_sort-text">
                  <span className="sort-icon" />
                  <select onChange={changePosition}>
                    <option value="">すべての役職</option>
                    <option value="null">未登録</option>
                    {positionQueries.map(positionQuery => (
                      <option
                        value={positionQuery.positionId}
                        key={positionQuery.positionId}
                      >
                        {positionQuery.positionName}
                      </option>
                    ))}
                  </select>
                  <div className="arrow-down-icon-wrapper">
                    <span className="arrow-down-icon" />
                  </div>
                </div>
              </h3>
              <ParksWordCloud words={words} dimensions={dimensions} />
            </div>
          </div>
          <div className="ranking_page__block">
            <CubeHistorySection
              headContents={headContents}
              cubeHistoryQueries={cubeHistoryQueries}
              filterDate={filterDate}
              setFilterDate={setFilterDate}
              memberRole={memberRole}
            />
          </div>
        </div>
      </main>
    </>
  )
}
