import React, { FC, useEffect, useState } from 'react'
import { ChatRoomAttendeeQuery, ChatRoomControllerService } from '../services'
import apiErrorHandler from '../api/apiErrorHandler'
import { useSearchParams } from 'react-router-dom'
import { useModal } from '../contexts/modalContext'
import { AddChatModal } from './AddChatModal'
import { ModalBase } from './ModalBase'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  closeModal: () => void
}
export const ChatRoomDetailModal: FC<Props> = ({ closeModal }) => {
  const [searchParams] = useSearchParams()
  const chatRoomId = searchParams.get('chatRoomId')
  const [chatRoomAttendees, setChatRoomAttendees] = useState<
    ChatRoomAttendeeQuery[]
  >([])
  const { openModal } = useModal()

  useEffect(() => {
    ChatRoomControllerService.getChatRoomAttendees(chatRoomId as string)
      .then(res => {
        setChatRoomAttendees(res)
      })
      .catch(apiErrorHandler)
  }, [])

  return (
    <ModalBase title="チャットを送る相手を探す" closeModal={closeModal}>
      <div className="mt-6 mb-20">
        <h2 className="font-bold text-sm">チャットのメンバー</h2>
        <ul className="flex flex-wrap max-h-48 mt-6 overflow-y-auto gap-4">
          {chatRoomAttendees.map(member => (
            <li key={member.memberId}>
              <div
                className="flex flex-col items-center cursor-pointer w-20 md:w-24 max-w-full gap-1"
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  window.location.href = `/member-detail/${member.memberId}`
                  closeModal()
                }}
              >
                <div className="w-[40%] aspect-square">
                  <img
                    src={
                      member.profileImageUrl
                        ? member.profileImageUrl
                        : '/icons/avatar-sample.png'
                    }
                    alt="プロフィール画面"
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
                <div className="flex items-center gap-1.5">
                  <p className="font-bold text-[12px]">{member.memberName}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-6 [&>button]:w-48">
          <PrimaryButton
            onClick={() => {
              openModal(AddChatModal)
            }}
          >
            メンバーを追加する
          </PrimaryButton>
        </div>
      </div>
    </ModalBase>
  )
}
