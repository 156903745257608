import { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { AnnouncementQuery, AnnouncementControllerService, MemberControllerService, MemberQuery } from '../../services';
import { SafeParagraph } from '../safeParagraph'
import { useNavigate } from 'react-router-dom'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'

interface AnnounceDetailProps {
    announceDetail?: AnnouncementQuery
  }
export const AnnounceDetail: FC<AnnounceDetailProps> = ({ announceDetail }) => {
  const [member, setMember] = useState({} as MemberQuery)
  const [endDate, setEndDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const navigate = useNavigate()

  useEffect(() => {
    // 0を指定することでログインユーザの情報を取得する
    MemberControllerService.getMember(0).then(setMember).catch(apiErrorHandler)

    if (announceDetail) {
        announceDetail.createdAt && setStartDate(format(new Date(announceDetail.createdAt), 'yyyy/MM/dd'));
        announceDetail.endDate && setEndDate(format(new Date(announceDetail.endDate), 'yyyy/MM/dd'));
    }
  }, [announceDetail]);

  const clickDeleteIcon =():void => {
      const isConfirmed = window.confirm('この告知を削除しますか？');
      const announcementId = announceDetail?.announcementId;

  if (isConfirmed && announcementId) {
      AnnouncementControllerService.deleteAnnouncement(announcementId).then(() => {
        toast.success('告知を削除しました');
        navigate(`/`)
      })
      .catch(apiErrorHandler);
    }
  }


  return (
    announceDetail ? (
      <div className="flex flex-col gap-3 min-w-80 max-sm:min-w-full my-2">
        <div className="flex gap-3 items-start mr-1">
          <img
            src={announceDetail.member?.profileImageUrl || '/icons/avatar-sample.png'}
            alt="プロフィール画像"
            width={32}
            height={32}
            className="rounded-full cursor-pointer"
            onClick={event => {
                event.preventDefault()
                event.stopPropagation()    
                navigate(`/member-detail/${announceDetail.member?.memberId}`)
            }}
          />
          <div className="flex flex-col gap-2  mr-auto min-w-0">
            <h2 className="text-sm break-words">
              <span className="font-bold cursor-pointer hover:underline"
                            onClick={event => {
                                event.preventDefault()
                                event.stopPropagation()    
                                navigate(`/member-detail/${announceDetail.member?.memberId}`)
                              }}>
                {announceDetail.member?.memberName}
              </span>さんが告知しました。
            </h2>
            <p className="w-full truncate text-xs text-annotation">
              告知期間：{startDate}〜{endDate}
            </p>
          </div>
          { member.memberId === announceDetail.member?.memberId && <p className='text-annotation text-xs flex items-center cursor-pointer'
            onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                clickDeleteIcon()
                }}>
            <img
            src={"/images/icon_trash_gray.svg"}
            alt="button-icon"
            className='w-5 h-5'
            />
            <span className="hidden sm:block">告知を削除する</span>
          </p>}
        </div>
        <div className="flex flex-col gap-1 py-3">
          <p className="text-sm font-bold break-words">{announceDetail.title}</p>
          <p className="w-full text-sm break-words"><SafeParagraph content={announceDetail.description} /></p>
        </div>
        <div className="w-full">
          <ul className="flex gap-3 flex-wrap">
            {announceDetail?.cubes?.map((cube, index) => (
              <li
                key={index}
                className="py-1.5 px-3 bg-seaBlue-sea-10 border border-seaBlue-sea-20 rounded-lg min-w-fit font-bold text-sm" 
              >
                {cube.cubeName}
              </li>
            ))}
          </ul>
        </div>
      </div>
    ): null
  );
};
