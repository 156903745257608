import { FC } from 'react'

export const Footer: FC = () => {
  return (
    <footer className="w-full">
      <small className="block p-2 text-gray-500 text-[10px] text-center">
        (C)︎2021 Rond Inc.
      </small>
    </footer>
  )
}
