import { FC, FormEventHandler, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import env from '../../config/env'
import axios from 'axios'
import { Button } from '../../components/button'

export const LoginMail: FC = () => {
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)
  const [passwordResetResult, setPasswordResetResult] = useState(
    queryParams.get('passwordResetResult') as string,
  )
  const [loginErrorMessage, setLoginErrorMessage] = useState('')

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    setPasswordResetResult('')
    setLoginErrorMessage('')

    event.preventDefault()
    const form = new FormData(event.currentTarget)
    const username = form.get('username') || ''
    const password = form.get('password') || ''

    if (username === '' || password === '') {
      setLoginErrorMessage('ユーザー名とパスワードは必須です')
      return
    }

    axios
      .post(
        `${env.BACKEND_BASE_URL}/api/sign-in`,
        { username, password },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: true,
        },
      )
      .then(res => {
        navigate('/')
        console.log(res)
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          setLoginErrorMessage('メールアドレスまたはパスワードが間違っています')
        } else {
          setLoginErrorMessage(
            'ログイン処理中にエラーが発生しました。しばらくしてから再試行してください。',
          )
        }
        // URLのパラメータを削除
        window.history.replaceState({}, '', '/login')
      })
  }

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main">
        <div className="form__wrapper">
          <h1 className="pagetitle">ログイン</h1>

          <hr className="separator" />

          {passwordResetResult === 'success' && (
            <>
              <p className="form__item">
                <span className="form__success">
                  パスワードをリセットしました
                </span>
              </p>
              <hr className="separator" />
            </>
          )}

          {loginErrorMessage && (
            <>
              <p className="form__item">
                <span className="form__error">{loginErrorMessage}</span>
              </p>
              <hr className="separator" />
            </>
          )}

          <form id="login-form" className="form" onSubmit={handleSubmit}>
            <p className="form__item">
              <label className="form__label">
                メールアドレス
                <input
                  id="email"
                  type="email"
                  name="username"
                  defaultValue=""
                />
              </label>
            </p>

            <p className="form__item">
              <label className="form__label">
                パスワード
                <input
                  id="password"
                  type="password"
                  name="password"
                  defaultValue=""
                />
              </label>
            </p>

            <div className="custom-login-button">
              <Button type="submit" className="btn">
                ログイン
              </Button>
            </div>
          </form>

          <div className="custom-mail-link">
            <a onClick={() => navigate('/password-reset-mail')}>
              パスワードを忘れた方はこちら
            </a>
          </div>
          <div className="custom-mail-link">
            <a onClick={() => navigate('/login')}>
              ソーシャルアカウントでログイン
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
