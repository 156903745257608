import {
  MemberCubeQuery,
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
} from '../../services'
import React, { FC, useEffect, useState } from 'react'
import { useModal } from '../../contexts/modalContext'
import { CubeDetailModal } from '../../modals/CubeDetailModal'
import { toast } from 'react-toastify'
import apiErrorHandler from '../../api/apiErrorHandler'
import { Button } from '../button'

interface Props {
  memberId: number // 所有者のメンバーID
  cube: MemberCubeQuery
  deleteButtonEnabled?: boolean
  onMoreViewCommentClick?: () => void
}

export const Cube: FC<Props> = ({
  memberId,
  cube,
  deleteButtonEnabled,
  onMoreViewCommentClick,
}) => {
  const { openModal } = useModal()

  const [isActiveReaction, setIsActiveReaction] = useState(false)
  const [reactionCount, setReactionCount] = useState(cube.giftMembers.length)

  useEffect(() => {
    setReactionCount(cube.giftMembers.length)
  }, [cube.giftMembers.length])

  // キューブにリアクション
  const reactCubes = () => {
    if (isActiveReaction) return
    setIsActiveReaction(true)

    const memberCubeForm = {
      memberId: memberId,
      cubeCategoryId: cube.cubeCategory.cubeCategoryId,
      cubeName: cube.cubeName,
    } as MemberCubeForm

    MemberCubeControllerService.createMemberCubes({
      memberCubes: [memberCubeForm],
    } as MemberCubesForm)
      .then(() => {
        toast.success('キューブにリアクションしました')
        setReactionCount(reactionCount + 1)
      })
      .catch(err => {
        toast.error('リアクションに失敗しました')
        apiErrorHandler(err)
      })
      .finally(() => {
        // 3秒間、非活性状態にしておく
        setTimeout(() => setIsActiveReaction(false), 3000)
      })
  }

  // ❤️のsvg要素
  const reactionIconSvg = () => {
    const activeClassName = isActiveReaction
      ? 'animate-bounceAnim'
      : 'animate-returnBackAnim'

    return (
      <svg
        className={`transition-all duration-300 ease-in-out ${activeClassName}`}
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clipPath)">
          <circle
            cx="8"
            cy="8.5"
            r="8"
            fill={isActiveReaction ? '#f46979' : 'white'}
          />
          <path
            className={isActiveReaction ? 'fill-white' : 'fill-[#f46979]'}
            d="M5.45373 4.57065C5.90333 4.49404 6.36439 4.51931 6.80292 4.64459C7.24145 4.76988 7.64627 4.99198 7.98755 5.29452L8.00635 5.31129L8.02362 5.29605C8.34934 5.0102 8.73227 4.7971 9.14686 4.67096C9.56145 4.54482 9.99818 4.50855 10.4279 4.56455L10.5529 4.58284C11.0945 4.67636 11.6008 4.91463 12.0181 5.27241C12.4354 5.63019 12.7482 6.09416 12.9233 6.61519C13.0985 7.13623 13.1295 7.69493 13.013 8.23212C12.8965 8.76932 12.6369 9.26502 12.2617 9.66674L12.1703 9.76071L12.1459 9.78154L8.36143 13.5299C8.27409 13.6164 8.15839 13.6682 8.03575 13.6759C7.91311 13.6836 7.79186 13.6465 7.69445 13.5716L7.6467 13.5299L3.84038 9.7597C3.43715 9.36736 3.15038 8.87107 3.01183 8.32579C2.87329 7.78052 2.88836 7.20753 3.0554 6.6703C3.22243 6.13307 3.53491 5.65255 3.95821 5.28196C4.38151 4.91137 4.89913 4.66518 5.45373 4.57065Z"
          />
        </g>
      </svg>
    )
  }

  return (
    <li>
      <Button
        id={`cube-${cube.memberCubeId}`}
        className={`cube-action-btn ${
          cube.giftMembers[0]?.memberId === memberId
            ? 'bg-white'
            : 'bg-[#e8f1fe]'
        } `}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          openModal(CubeDetailModal, {
            memberCubeId: cube.memberCubeId,
            deleteButtonEnabled: deleteButtonEnabled,
            onMoreViewCommentClick: onMoreViewCommentClick,
          })
        }}
      >
        <p className="flex items-center max-w-md font-bold text-sm gap-2 relative">
          <span>{cube.cubeName}</span>
        </p>
        <span
          className="flex"
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            reactCubes()
          }}
        >
          {reactionIconSvg()}
        </span>
        {reactionCount > 1 && (
          <span className="absolute top-[-8px] right-[-7px] min-w-5 px-1 py-0.5 rounded-full bg-[#1a88ed] text-white font-semibold text-[10px] text-center">
            {reactionCount}
          </span>
        )}
      </Button>
    </li>
  )
}
