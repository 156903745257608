import React, { FC, useEffect, useState } from 'react'
import { HeaderType } from '../enums/HeaderType'
import { useLocation, useNavigate } from 'react-router-dom'
import apiErrorHandler from '../api/apiErrorHandler'
import { MemberControllerService, MemberQuery } from '../services'
import { useModal } from '../contexts/modalContext'
import { useRecoilState } from 'recoil'
import { loggedInMemberState } from '../lib/atoms'
import { ChatRoomDetailModal } from '../modals/ChatRoomDetailModal'
import { Button } from '../components/button'

interface Props {
  headerType?: HeaderType
  headerTitle?: string
  isChatRoomTitle?: boolean
}

export const Header: FC<Props> = ({
  headerType = HeaderType.DEFAULT,
  headerTitle,
  isChatRoomTitle,
}) => {
  const { openModal } = useModal()
  const location = useLocation()
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const sort = query.get('sort') as 'COMMON' | 'NEW'

  const navigate = useNavigate()
  const [keyword, setKeyword] = useState((query.get('keyword') as string) || '')
  const [composing, setComposing] = useState(false)
  const [member, setMember] = useState({} as MemberQuery)

  const [, setLoggedInMemberId] = useRecoilState(loggedInMemberState)

  useEffect(() => {
    if (headerType === HeaderType.NOT_LOGIN) return
    MemberControllerService.getMember(0)
      .then(res => {
        setMember(res)
      })
      .catch(apiErrorHandler)

    MemberControllerService.getLoggedInMember().then(loggedInMember => {
      const isUserLoggedIn = !!loggedInMember.memberId
      if (isUserLoggedIn) {
        setLoggedInMemberId({
          memberId: loggedInMember.memberId,
          workspaceId: loggedInMember.workspaceId,
        })
      }
    })
  }, [])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !composing) {
      if (keyword === '') {
        const sortQuery = sort ? `?sort=${sort}` : ''
        window.location.href = `/member-list${sortQuery}`
        return
      } else {
        const sortQuery = sort ? `&sort=${sort}` : ''
        const aiFlg = document.getElementById('ai-search-checkbox')
          ? (document.getElementById('ai-search-checkbox') as HTMLInputElement)
              .checked
          : false
        const aiSearchQuery = aiFlg ? '' : '&ai=false'
        window.location.href = `/member-list?keyword=${keyword}${sortQuery}${aiSearchQuery}`
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value)
  }

  const handleCompositionStart = () => {
    setComposing(true)
  }

  const handleCompositionEnd = () => {
    setComposing(false)
  }

  switch (headerType) {
    case HeaderType.DEFAULT:
      return (
        <header className="max-w-full md:max-w-[calc(100%-223px)] bg-white md:bg-transparent fixed top-0 right-0 z-[100] w-full px-4 py-2 md:px-12 md:py-6">
          <div className="gap-2 md:gap-6 flex justify-between items-center max-w-[calc(rem(1617px)+(rem(40px)*2))] my-0 mx-auto">
            <svg
              className="block absolute left-8 md:left-16 transform-[translateY(-50%)] w-5 h-5 bg-[#00866] content-none fill-accentSuccess z-10"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.0918 16.9075L15.0002 13.8409C16.2002 12.3445 16.7814 10.4453 16.6242 8.5336C16.4669 6.62194 15.5832 4.84317 14.1548 3.56306C12.7263 2.28294 10.8617 1.59878 8.94427 1.65125C7.02686 1.70371 5.20243 2.48882 3.84612 3.84514C2.4898 5.20146 1.70469 7.02589 1.65222 8.94329C1.59976 10.8607 2.28392 12.7253 3.56403 14.1538C4.84415 15.5822 6.62292 16.466 8.53458 16.6232C10.4462 16.7804 12.3455 16.1993 13.8418 14.9992L16.9085 18.0659C16.986 18.144 17.0781 18.206 17.1797 18.2483C17.2812 18.2906 17.3902 18.3124 17.5002 18.3124C17.6102 18.3124 17.7191 18.2906 17.8206 18.2483C17.9222 18.206 18.0144 18.144 18.0918 18.0659C18.242 17.9105 18.326 17.7028 18.326 17.4867C18.326 17.2706 18.242 17.0629 18.0918 16.9075ZM9.16683 14.9992C8.01311 14.9992 6.88529 14.6571 5.926 14.0161C4.96672 13.3751 4.21904 12.4641 3.77753 11.3982C3.33602 10.3323 3.2205 9.15938 3.44558 8.02783C3.67066 6.89627 4.22624 5.85687 5.04204 5.04107C5.85785 4.22526 6.89725 3.66969 8.0288 3.44461C9.16036 3.21953 10.3332 3.33505 11.3992 3.77656C12.4651 4.21807 13.3761 4.96574 14.0171 5.92503C14.658 6.88432 15.0002 8.01213 15.0002 9.16585C15.0002 10.713 14.3856 12.1967 13.2916 13.2906C12.1977 14.3846 10.7139 14.9992 9.16683 14.9992Z"
                fill="#008666"
              />
            </svg>
            <input
              type="text"
              className="
                  w-[480px] max-w-full max-h-full
                  bg-gray-100 md:bg-white text-lg rounded-lg pl-12 py-2 shadow md:shadow-lg
                  coach-mark_step-sp-2 coach-mark_step-pc-2
                "
              placeholder="人やキーワードで検索"
              value={keyword}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onCompositionStart={handleCompositionStart}
              onCompositionEnd={handleCompositionEnd}
            />
            <div className="flex items-center ml-auto gap-4">
              <Button
                className="block md:flex w-8 md:w-11 h-8 md:h-11 justify-center items-center rounded-full bg-white shadow md:shadow-lg gap-1"
                onClick={() => {
                  window.location.href = '/profile'
                }}
              >
                <img
                  src={
                    member.profileImageUrl
                      ? member.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  className="w-full h-full md:w-8 md:h-8 rounded-full object-cover coach-mark_step-sp-3 coach-mark_step-pc-3"
                />
              </Button>
            </div>
          </div>
        </header>
      )
    case HeaderType.SIMPLE_WITHOUT_SEARCH:
      return (
        <header className="header_login">
          <div className="header_login__inner">
            {headerTitle && (
              <div className="header_login__left-box">
                <Button
                  className="header_login__back-btn"
                  onClick={() => {
                    location.pathname.includes('chat-detail')
                      ? navigate('/chat-list')
                      : window.history.back()
                  }}
                >
                  <img src="/images/icon_arrow_left.svg" alt="戻る" />
                </Button>
                <h1 className="header_login__title">
                  {isChatRoomTitle ? (
                    <Button
                      className="header_login__title__link"
                      onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        openModal(ChatRoomDetailModal)
                      }}
                    >
                      {headerTitle}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 21"
                        fill="#858a99"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z" />
                      </svg>
                    </Button>
                  ) : (
                    <span>{headerTitle}</span>
                  )}
                </h1>
              </div>
            )}
            <div className="header_login__right-box">
              <Button
                className="header_login__btn_avatar"
                onClick={() => {
                  window.location.href = '/profile'
                }}
              >
                <img
                  src={
                    member.profileImageUrl
                      ? member.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  className="header_login__avatar_img"
                />
              </Button>
            </div>
          </div>
        </header>
      )
    case HeaderType.SIMPLE:
      return (
        <header className="header header-loggedin">
          <div className="header__inner">
            <div className="header__left">
              <div className="header__logo">
                <a href="/" className="header__logo-link">
                  <img
                    src="/images/parks_logo.svg"
                    alt="parks"
                    width="87"
                    height="28"
                  />
                </a>
              </div>
              {/* TODO 必要なら追加 */}
              {/*
              <span className="header__name">株式会社〇〇</span>
              */}
              <div className="header__search">
                <div role="search" id="searchform">
                  <input
                    type="text"
                    id="searchsubmit"
                    className="header__search-btn"
                    value={keyword}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                  />
                  <input
                    type="text"
                    name=""
                    id="search-text"
                    placeholder="人やキーワードで検索"
                    className="header__search-input"
                    value={keyword}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                  />
                </div>
              </div>
            </div>
            <div className="header__right">
              {/*
              <div
                className="header-timeline"
                onClick={() => navigate('/timeline')}
              >
                <img
                  src="/images/icon_timeline.svg"
                  alt=""
                  width="20"
                  height="20"
                />
              </div>
              */}
              <Button
                className="header_login__btn_avatar"
                onClick={() => {
                  window.location.href = '/profile'
                }}
              >
                <img
                  src={
                    member.profileImageUrl
                      ? member.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  className="header_login__avatar_img"
                />
              </Button>
            </div>
          </div>
        </header>
      )
    case HeaderType.NOT_LOGIN:
      return (
        <header className="header header--narrow">
          <div className="header__logo">
            <div className="header__logo-link">
              <img
                src="/images/parks_logo.svg"
                alt="parks"
                width="87"
                height="28"
              />
            </div>
          </div>
        </header>
      )
    case HeaderType.SETTING:
      return (
        <header className="header_login setting">
          <div className="header_login__inner">
            <div className="header_login__input-box">
              <input
                type="text"
                placeholder="人やキーワードで検索"
                value={keyword}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="header_login__right-box">
              {/* TODO 通知画面がないので非表示 */}
              {/*
              <button className="header_login__btn_nortification">
                <img
                  src="/images/icon_timeline.svg"
                  alt="ベルマークにチェックあり"
                />
              </button>
              */}

              <Button
                className="header_login__btn_avatar"
                onClick={() => {
                  window.location.href = '/profile'
                }}
              >
                <img
                  src={
                    member.profileImageUrl
                      ? member.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  className="header_login__avatar_img"
                />
              </Button>
            </div>

            <Button
              className="setting__header-icon"
              onClick={() => {
                // bodyのclassからsetting-submenuを削除
                document.body.classList.remove(
                  'setting-submenu',
                  'timeline-settings',
                  'personal-settings',
                )
              }}
            ></Button>
            <h1 className="setting__header-sp-title">設定</h1>
          </div>
        </header>
      )
    case HeaderType.BACK:
      return (
        <header className="header header_search">
          <Button className="header__back-btn" onClick={() => navigate(-1)}>
            <img src="/images/icon_arrow_left.svg" alt="戻るボタン" />
          </Button>
        </header>
      )
  }
}
