import { FC, useState } from 'react'
import { MemberCubeControllerService } from '../services'
import React from 'react'
import apiErrorHandler from '../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { ModalBase } from './ModalBase'
import PrimaryButton from '../components/button/PrimaryButton'
import DeleteButton from '../components/button/DeleteButton'

interface Props {
  memberCubeGiftMemberId: number
  memberCubeId: number
  giftMemberId: number
  giftMessage: string
  isGiftMember: boolean
  closeModal: () => void
}

export const CubeEditModal: FC<Props> = ({
  memberCubeGiftMemberId,
  memberCubeId,
  giftMemberId,
  giftMessage,
  isGiftMember,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [inputGiftMessage, setInputGiftMessage] = useState<string>(giftMessage)

  // キューブ更新
  const updateMemberCube = (deleteFlg: boolean) => {
    if (!deleteFlg && inputGiftMessage === '') {
      return
    }
    setIsLoading(true)
    MemberCubeControllerService.updateMemberCube({
      memberCubeGiftMemberId: memberCubeGiftMemberId,
      memberCubeId: memberCubeId,
      giftMemberId: giftMemberId,
      giftMessage: deleteFlg ? undefined : inputGiftMessage,
    })
      .then(() => {
        if (deleteFlg) {
          toast.success('説明を削除しました')
        } else {
          toast.success('更新しました')
        }
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ModalBase
      title="キューブを更新する"
      closeModal={closeModal}
      isLoading={isLoading}
    >
      <div className="mt-6 mb-20">
        <div className="rounded-xl bg-gray-100">
          <textarea
            value={inputGiftMessage}
            placeholder="きっかけや理由などをコメントしましょう！"
            onChange={e => setInputGiftMessage(e.target.value)}
            disabled={!isGiftMember}
            className="block w-full h-40 p-3 border-none bg-transparent text-inherit resize-none"
          ></textarea>
        </div>
        <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-6 [&>button]:w-32">
          <DeleteButton
            onClick={() => {
              if (confirm('本当に削除しますか？')) {
                setInputGiftMessage('')
                updateMemberCube(true)
              }
            }}
          >
            削除する
          </DeleteButton>
          <PrimaryButton onClick={() => updateMemberCube(false)}>
            更新する
          </PrimaryButton>
        </div>
      </div>
    </ModalBase>
  )
}
