import React, { FC, useEffect, useState } from 'react'
import ReactCrop, { makeAspectCrop, Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { uploadImage } from '../lib/file'
import { ModalBase } from './ModalBase'
import SecondaryButton from '../components/button/SecondaryButton'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  closeModal: () => void
  image: string
  setImage: (image: string) => void
}
export const ImageUploadModal: FC<Props> = ({
  closeModal,
  image,
  setImage,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [crop, setCrop] = useState<Crop | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [dataUrl, setDataUrl] = useState<string>('')

  useEffect(() => {
    if (selectedFile) {
      const initialCrop = makeAspectCrop(
        { unit: 'px', width: 100, height: 100 },
        1,
        100,
        100,
      )
      setCrop(initialCrop)
    }
  }, [selectedFile])

  // ファイル読み込み
  const processFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = handleImageLoad(file)
    reader.readAsDataURL(file)
  }

  // 画像ファイル処理
  const handleImageLoad =
    (originalFile: File) => (e: ProgressEvent<FileReader>) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const aspectRatio = img.width / img.height
        let newWidth = 300
        let newHeight = 300 / aspectRatio

        if (aspectRatio < 1) {
          newWidth = 300 * aspectRatio
          newHeight = 300
        }

        canvas.width = newWidth
        canvas.height = newHeight

        ctx?.drawImage(img, 0, 0, newWidth, newHeight)

        canvas.toBlob(blob => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const resizedFile = new File([blob!], originalFile.name, {
            type: originalFile.type,
          })
          setSelectedFile(resizedFile)
        }, originalFile.type)
      }

      img.src = e.target?.result as string
    }

  // ファイル選択時の処理
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const isValidFile =
        file.size <= 10 * 1024 * 1024 &&
        ['image/jpeg', 'image/png', 'image/gif'].includes(file.type)

      if (isValidFile) {
        setSelectedFile(file)
        setErrorMessage(null)
        processFile(file)
      } else {
        setErrorMessage(
          '無効なファイル形式またはファイルサイズが制限を超えています。',
        )
        setSelectedFile(null)
      }
    }
  }

  // トリミング完了時に画像確定
  const handleCropComplete = (completedCrop: Crop) => {
    setCrop(completedCrop)
    imageSave()
  }

  const imageSave = () => {
    if (!selectedFile) {
      return
    }

    const canvas = document.createElement('canvas')
    const image = new Image()
    image.src = URL.createObjectURL(selectedFile)

    image.onload = () => {
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height

      const ctx = canvas.getContext('2d')
      if (ctx) {
        const cropX = crop?.x || 0
        const cropY = crop?.y || 0
        const cropWidth = crop?.width || image.width
        const cropHeight = crop?.height || image.height

        const aspectRatio = cropWidth / cropHeight
        if (aspectRatio > 1) {
          canvas.width = cropWidth
          canvas.height = cropWidth / aspectRatio
        } else {
          canvas.width = cropHeight * aspectRatio
          canvas.height = cropHeight
        }

        ctx.drawImage(
          image,
          cropX * scaleX,
          cropY * scaleY,
          cropWidth * scaleX,
          cropHeight * scaleY,
          0,
          0,
          canvas.width,
          canvas.height,
        )

        setDataUrl(canvas.toDataURL())
      }
    }
  }

  const navigateToNextPage = () => {
    uploadImage(dataUrl).then(res => {
      setImage(res || '')
      closeModal()
    })
  }

  return (
    <ModalBase title="プロフィール画像の編集" closeModal={closeModal}>
      <p className="mt-10 text-gray-500 text-sm text-left">
        プロフィールに使用する画像のアップロード、画像のトリミングを行うことができます。
      </p>
      {selectedFile ? (
        <div className="w-full mt-10 mx-auto rounded-lg place-items-center">
          <div style={{ maxWidth: '300px', margin: 'auto' }}>
            <ReactCrop
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={handleCropComplete}
              aspect={1}
            >
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected"
                style={{ maxWidth: '300px', margin: 'auto' }}
              />
            </ReactCrop>
          </div>
        </div>
      ) : image ? (
        <div className="w-full mt-10 mx-auto rounded-lg place-items-center">
          <div style={{ maxWidth: '300px', margin: 'auto' }}>
            <img
              src={image}
              alt="Selected"
              style={{ maxWidth: '300px', margin: 'auto' }}
            />
          </div>
        </div>
      ) : (
        <div className="grid w-[238px] h-40 mt-10 mx-auto rounded-lg bg-gray-300 place-items-center">
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="64" height="64" rx="32" fill="#8A8F9F" />
            <g style={{ mixBlendMode: 'multiply' }}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.9995 32.2469C37.3014 32.2469 41.5995 27.9488 41.5995 22.6469C41.5995 17.3449 37.3014 13.0469 31.9995 13.0469C26.6976 13.0469 22.3995 17.3449 22.3995 22.6469C22.3995 27.9488 26.6976 32.2469 31.9995 32.2469ZM31.9995 57.8469C40.066 57.8469 47.2735 54.0961 51.9693 48.2471C47.2735 42.3978 40.0658 38.6469 31.9991 38.6469C23.9326 38.6469 16.7252 42.3976 12.0293 48.2466C16.7251 54.0959 23.9328 57.8469 31.9995 57.8469Z"
                fill="#8A8F9F"
              />
            </g>
          </svg>
        </div>
      )}
      <div className="flex flex-col my-10 text-left">
        <div className="mt-0">
          <div className="font-bold text-sm block text-left">
            <p className="mb-2">ファイルから抽出</p>
            <label className="inline-flex items-center bg-green text-white px-3 py-2 rounded-lg text-xs font-bold transition ease-out duration-300 hover:opacity-60 gap-1 cursor-pointer">
              ファイルをアップロード
              <input
                type="file"
                id="file"
                className="hidden"
                onChange={handleFileChange}
              />
            </label>
            {errorMessage && (
              <p className="text-xs text-red-500 mt-1.5">{errorMessage}</p>
            )}
            <p className="mt-2 text-gray-500 font-normal text-sm">
              10MBまでのjpeg, pngまたはgifファイルをアップロード可能
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col text-left mt-5 mb-0">
        <div>
          <div className="flex justify-center gap-2 [&>button]:w-32">
            <SecondaryButton onClick={closeModal}>キャンセル</SecondaryButton>
            <PrimaryButton
              onClick={navigateToNextPage}
              disabled={!selectedFile}
            >
              保存
            </PrimaryButton>
          </div>
        </div>
      </div>
    </ModalBase>
  )
}
