import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import {
  AdminControllerService,
  MemberControllerService,
  MemberRoleQuery,
} from '../../services'
import { toast } from 'react-toastify'

export const RecommendEmail: FC = () => {
  const navigate = useNavigate()
  const [csv, setCsv] = useState('')

  const sendEmail = () => {
    if (csv !== '') {
      // 確認モーダルを表示
      if (!window.confirm('メールを送信します。よろしいですか？')) {
        return
      }
      const inputElement = document.getElementById('file') as HTMLInputElement
      AdminControllerService.sendRecommendEmail({
        // @ts-ignore
        file: inputElement.files?.[0],
      })
        .then(res => {
          if (res.length === 0) {
            toast.success('メールを送信しました。')
          } else {
            // エラーメッセージを作成
            let errorMessage = '以下の行の送信に失敗しました。\n'
            res.forEach(uploadResponse => {
              errorMessage +=
                uploadResponse.lineNo +
                ': ' +
                uploadResponse.errorMessage +
                '\n'
            })
            alert(errorMessage)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('メール送信に失敗しました。')
        })
    }
  }

  useEffect(() => {
    // ログインユーザーが管理者でない場合、ホームへリダイレクト
    MemberControllerService.getMemberRole().then(res => {
      if (res.memberRole !== MemberRoleQuery.memberRole.ADMIN) {
        navigate('/')
      }
    })
  }, [])

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main registration">
        <div className="form__wrapper form__wrapper--wide">
          <h1 className="pagetitle">紹介メール送信</h1>
          <div className="form">
            <div className="form__item">
              <label className="form__label">
                CSV
                <input
                  id="file"
                  type="file"
                  accept="text/csv"
                  onChange={e => setCsv(e.target.value)}
                />
              </label>
            </div>
            <p className="form__submit">
              <button type="button" className="btn" onClick={sendEmail}>
                メール送信
              </button>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
