import React, { FC, useState } from 'react'
import {
  AdminControllerService,
  AdminMember,
  MemberRoleMap,
  MemberRoleQuery,
  MemberUpdateForm,
} from '../services'
import apiErrorHandler from '../api/apiErrorHandler'
import Loading from 'react-loading'
import { toast } from 'react-toastify'
import SecondaryButton from '../components/button/SecondaryButton'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  adminMember: AdminMember
  memberRoleMaps: MemberRoleMap[]
  disabled?: boolean
  closeModal: () => void
}

export const AdminProfileEditModal: FC<Props> = ({
  adminMember,
  memberRoleMaps,
  disabled,
  closeModal,
}) => {
  const [memberName, setUserName] = useState(adminMember.memberName)
  const [email, setEmail] = useState(adminMember.email)
  const [memberRole, setMemberRole] = useState(adminMember.memberRole)
  const [isLoading, setIsLoading] = useState(false)

  const update = () => {
    // 初期表示と同じ場合は何もしない
    if (
      memberName === adminMember.memberName &&
      email === adminMember.email &&
      memberRole === adminMember.memberRole
    ) {
      return
    }
    // バリデーション
    if (!validate()) return

    setIsLoading(true)
    AdminControllerService.updateMemberFromAdmin(adminMember.memberId, {
      memberName,
      email,
      memberRole,
    } as MemberUpdateForm)
      .then(() => {
        closeModal()
        toast.success('メンバー情報を更新しました')
      })
      .catch(err => {
        apiErrorHandler(err)
        setIsLoading(false)
      })
  }

  const validate = () => {
    if (memberName === '') {
      toast.error('名前を入力してください')
      return false
    }
    if (email === '') {
      toast.error('メールアドレスを入力してください')
      return false
    }
    if (
      !/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(
        email,
      )
    ) {
      toast.error('メールアドレスの形式が正しくありません')
      return false
    }
    return true
  }

  return (
    <div className="flex fixed flex-col justify-start top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[620px] max-w-full max-h-[90%] rounded-lg bg-white p-6">
      {isLoading ? (
        <Loading className="loading" type="spin" color="#007559" />
      ) : (
        <>
          <div className="relative flex items-center h-[112px] px-8 py-6 bg-gradient-to-t from-[rgba(8,10,7,0.45)] to-[rgba(8,10,7,0.45)] gap-4 md:max-h-[92px] md:px-4 md:py-3">
            <img
              className="absolute inset-0 w-full h-full object-cover z-[-1]"
              src="/images/bg_profile_edit.jpeg"
              alt="プロフィール背景画像"
            />
            <div className="relative w-16 h-16 rounded-full">
              <img
                src={
                  adminMember.profileImageUrl
                    ? adminMember.profileImageUrl
                    : '/icons/avatar-sample.png'
                }
                alt="プロフィール画像"
                className="w-full h-full rounded-full object-cover"
              />
            </div>
          </div>

          <div className="mb-16 py-4">
            <dl className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <dt className="text-gray-900 font-bold text-sm">名前</dt>
                <dd className="overflow-hidden rounded-xl bg-[#edf0f7]">
                  <input
                    type="text"
                    value={memberName}
                    disabled={disabled}
                    onChange={e => setUserName(e.target.value)}
                    className="block w-full p-3 px-4 border-none rounded-xl bg-transparent text-inherit placeholder-gray-400 disabled:text-gray-400"
                  />
                </dd>
              </div>
              <div className="flex flex-col gap-1">
                <dt className="text-gray-900 font-bold text-sm">
                  メールアドレス
                </dt>
                <dd className="overflow-hidden rounded-xl bg-[#edf0f7]">
                  <input
                    type="text"
                    placeholder="メールアドレスを入力してください"
                    value={email}
                    disabled={disabled}
                    onChange={e => setEmail(e.target.value)}
                    className="block w-full p-3 px-4 border-none rounded-xl bg-transparent text-inherit placeholder-gray-400 disabled:text-gray-400"
                  ></input>
                </dd>
              </div>
              <div className="flex flex-col gap-1">
                <dt className="text-gray-900 font-bold text-sm">ロール</dt>
                <dd className="overflow-hidden rounded-xl bg-[#edf0f7]">
                  <select
                    value={memberRole}
                    onChange={e =>
                      setMemberRole(
                        e.target.value as MemberRoleQuery.memberRole,
                      )
                    }
                    disabled={disabled}
                    className="block w-full p-3 px-4 border-none rounded-xl bg-transparent text-inherit placeholder-gray-400 disabled:text-gray-400"
                  >
                    {memberRoleMaps.map((memberRoleMap, index) => (
                      <option key={index} value={memberRoleMap.name}>
                        {memberRoleMap.label}
                      </option>
                    ))}
                  </select>
                </dd>
              </div>
            </dl>
            <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2 [&>button]:w-32">
              <SecondaryButton onClick={closeModal}>
                {disabled ? '閉じる' : 'キャンセル'}
              </SecondaryButton>
              {!disabled && (
                <PrimaryButton onClick={update}>保存</PrimaryButton>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
