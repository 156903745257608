import React from 'react'
import { SimpleMemberQuery } from '../../services'
import { SafeParagraph } from '../safeParagraph'
import { useNavigate } from 'react-router-dom'
interface MemberProfileCardProps {
  member: SimpleMemberQuery
  className?: string
  singleWord?: string | undefined
  onClickTimelineCard?: () => void
}

const MemberProfileCard: React.FC<MemberProfileCardProps> = ({
  member,
  className,
  singleWord,
  onClickTimelineCard,
}) => {
  const navigate = useNavigate()
  return (
    <div
      className={`home-timeline__card-under ${className}`}
      onClick={onClickTimelineCard}
    >
      {(singleWord || member.singleWord) && (
        <div className="home-timeline__card-under-comment break-words">
          <SafeParagraph content={singleWord || member.singleWord} />
        </div>
      )}
      <div className="home-timeline__card-under-member max-w-full">
        <div className="home-timeline__card-under-avater">
          <img
            src={
              member.profileImageUrl
                ? member.profileImageUrl
                : '/icons/avatar-sample.png'
            }
            onClick={event => {
              event.stopPropagation()
              navigate(`/member-detail/${member.memberId}`)
            }}
            alt="プロフィール画像"
            width={64}
            height={64}
          />
        </div>
        <div className="home-timeline__card-under-profile">
          <div
            className="home-timeline__card-under-name w-60 break-words"
            onClick={event => {
              event.stopPropagation()
              navigate(`/member-detail/${member.memberId}`)
            }}
          >
            {member.memberName}
          </div>
          <div className="home-timeline__card-under-position">
            {member.position?.positionName}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberProfileCard
