import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaOptionsType } from 'embla-carousel';
import AnnounceCard from './AnnounceCard';
import GradientOverlay from './GradiantOverlay';
import ScrollButton from './ScrollButton';
import { AnnouncementQuery } from '../../services';

interface AnnounceCardListProps {
  memberId: number
  onDelete: () => void
  showButtons?: boolean; // ボタンの表示/非表示を切り替える
  showGradients?: boolean; // ボカシの表示/非表示を切り替える
  announcements?: AnnouncementQuery[];
  cardClassName?: string;
  cardWrapClassName?: string;
}

const AnnounceCardList: React.FC<AnnounceCardListProps> = ({
  memberId,
  onDelete,
  showButtons = true,
  showGradients = true,
  announcements = [],
  cardClassName = "",
  cardWrapClassName = "",
}) => {
  const OPTIONS: EmblaOptionsType = { dragFree: true };
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  const updateScrollButtonsState = useCallback(() => {
    if (!emblaApi) return;
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      updateScrollButtonsState();
      emblaApi.on('select', updateScrollButtonsState);
      emblaApi.on('reInit', updateScrollButtonsState);
      return () => {
        emblaApi.off('select', updateScrollButtonsState);
        emblaApi.off('reInit', updateScrollButtonsState);  
      };
    }
  }, [emblaApi]);

  return (
    <div className="relative">
      {/* ボカシ表示の切り替え */}
      <div className="hidden md:block">
      {showGradients && (
        <GradientOverlay showLeft={canScrollPrev} showRight={canScrollNext}/>
      )}
      </div>
      <div className={`overflow-x-hidden scrollbar-none whitespace-nowrap`} ref={emblaRef}>
        <div className={`flex gap-4 ${cardWrapClassName}`}>
          {announcements.map((announcement, index) => (
            <AnnounceCard
              key={index}
              announcement={announcement}
              cardClassName={cardClassName}
              memberId={(memberId)}
              onDelete={onDelete}
            />
          ))}
        </div>
      </div>

      {/* ボタン表示の切り替え */}
      <div className="hidden md:block">
        {showButtons && canScrollPrev && (
          <ScrollButton direction="left" onClick={scrollPrev}/>
        )}
        {showButtons && canScrollNext && (
          <ScrollButton direction="right" onClick={scrollNext} />
        )}
      </div>
    </div>
  );
};

export default AnnounceCardList;
