import React, { FC } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { SideNavi } from '../../layouts/sideNavi'
import { CubeSendModal } from '../../modals/CubeSendModal'
import { useModal } from '../../contexts/modalContext'

export const Help: FC = () => {
  const { openModal } = useModal()
  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="main-sidenav help_page-main-sidenav">
        <div className="main_sidenav__inner help_page__inner">
          <div className="help_page__block">
            <div className="help_page__content">
              <h2 className="help_page__title">ヘルプ</h2>
              <h3 className="help_page__subtitle">基本的な使い方</h3>
              <ul className="help_page__index">
                <li>
                  <a href="#help-parks">parksでできること</a>
                </li>
                <li>
                  <a href="#help-cube">「キューブ」とは？</a>
                </li>
                <li>
                  <a href="#help-step01">
                    ステップ1：あなたのことを知ってもらおう
                  </a>
                </li>
                <li>
                  <a href="#help-step02">ステップ2：メンバーを知ろう</a>
                </li>
                <li>
                  <a href="#help-step03">ステップ3：繋がりたい人を見つけよう</a>
                </li>
              </ul>
              <section className="help_page__section" id="help-parks">
                <h4 className="help_page__section-title">parksでできること</h4>
                <p className="help_page__text">
                  parksは、あなたのwill（やりたいこと）を後押ししてくれる仲間を見つけることをサポートします。
                  <br />
                  キューブによって個々の能力を可視化し、AIを活用して効果的なつながりと連携を促進します。
                </p>
                <div className="help_page__image help_page__image-parks">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="/images/help-parks-img-sp.webp"
                      type="image/webp"
                    />
                    <img
                      src="/images/help-parks-img.webp"
                      alt=""
                      width={516}
                      height={236}
                    />
                  </picture>
                </div>
                <button
                  type="button"
                  className="btn_secondary-l help_page__btn"
                  onClick={() => openModal(CubeSendModal)}
                >
                  早速キューブを登録する
                </button>
              </section>
              <section className="help_page__section" id="help-cube">
                <h4 className="help_page__section-title">「キューブ」とは？</h4>
                <p className="help_page__text">
                  parksでは一人ひとりの個性のかけらを「キューブ」と呼んでいます。
                  <br />
                  あなたの興味・関心、スキル・専門知、パーソナリティをキューブとして登録しましょう。
                </p>
                <div className="help_page__image">
                  <img
                    src="/images/help-cube-img.webp"
                    alt=""
                    width={343}
                    height={151}
                  />
                </div>
                <button
                  type="button"
                  className="btn_secondary-l help_page__btn"
                  onClick={() => openModal(CubeSendModal)}
                >
                  早速キューブを登録する
                </button>
              </section>
              <section className="help_page__section" id="help-step01">
                <h4 className="help_page__section-title">
                  ステップ1：あなたのことを知ってもらおう
                </h4>
                <p className="help_page__text">
                  プロフィールを充実させることであなたのことを知ってもらうことができます。
                  <br />
                  キューブには、あなたの専門知・スキル・経験・関連PJ・趣味・興味あること等を記載してください！
                </p>
              </section>
              <section className="help_page__section" id="help-step02">
                <h4 className="help_page__section-title">
                  ステップ2：メンバーを知ろう
                </h4>
                <p className="help_page__text">
                  あなたが参加しているワークスペースのコミュニティメンバーを知ろう
                  <br />
                  タイムラインに流れてくるメンバーの情報で気になる人がいたらプロフィールを見に行こう！
                  <br />
                  聞きたいいことや連携したいことがあれば、チャットで連絡しみてみよう
                </p>
              </section>
              <section className="help_page__section" id="help-step03">
                <h4 className="help_page__section-title">
                  ステップ3：繋がりたい人を見つけよう
                </h4>
                <p className="help_page__text">
                  繋がりたいメンバーのキーワードを検索するとAIが適任者をレコメンドしてくれます
                  <br />
                  プロフィールを見て繋がりたい人であればチャットで連絡してみよう！
                </p>
                <div className="help_page__step-image">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="/images/help-step3-img01.webp"
                      type="image/webp"
                    />
                    <img
                      src="/images/help-step3-img01.webp"
                      alt=""
                      width={343}
                      height={228}
                    />
                  </picture>
                </div>
                <p className="help_page__note">
                  ヘッダーのアイコンからプロフィールページへ移動できます
                </p>
                <div className="help_page__step-image">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="/images/help-step3-img02.webp"
                      type="image/webp"
                    />
                    <img
                      src="/images/help-step3-img02.webp"
                      alt=""
                      width={343}
                      height={228}
                    />
                  </picture>
                </div>
                <p className="help_page__note">
                  ヘッダーの検索からキーワードで調べることができます
                </p>
                <div className="help_page__step-image">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="/images/help-step3-img03.webp"
                      type="image/webp"
                    />
                    <img
                      src="/images/help-step3-img03.webp"
                      alt=""
                      width={343}
                      height={380}
                    />
                  </picture>
                </div>
                <p className="help_page__note">
                  プロフィールページでメンバーの所属や業務内容、キューブなどを見て、チャットを送ることができます。
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
