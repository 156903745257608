import React, { FC } from 'react'

interface TabSwitcherProps {
  activeTab: string
  setActiveTab: (tab: string) => void
  tabs: { key: string; label: string }[] // タブのテキストを配列で渡す
}

const TabSwitcher: FC<TabSwitcherProps> = ({
  activeTab,
  setActiveTab,
  tabs,
}) => {
  return (
    <div className="flex gap-3 md:gap-4 overflow-x-auto scrollbar-none">
      {tabs.map(tab => (
        <button
          key={tab.key}
          className={`tab-button ${activeTab === tab.key ? 'active' : ''} min-w-fit text-sm font-bold	last:mr-6`}
          onClick={() => setActiveTab(tab.key)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  )
}

export default TabSwitcher
