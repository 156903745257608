import React, { FC, useState } from 'react'
import {
  MemberControllerService,
  MemberQuery,
  MemberUpdateForm,
} from '../services'
import apiErrorHandler from '../api/apiErrorHandler'
import Loading from 'react-loading'
import { toast } from 'react-toastify'
import { ImageUploadModal } from './ImageUploadModal'
import { useModal } from '../contexts/modalContext'
import { Button } from '../components/button'

interface Props {
  member: MemberQuery
  closeModal: () => void
}

export const ProfileEditModal: FC<Props> = ({ member, closeModal }) => {
  const { openModal } = useModal()

  const [memberName, setUserName] = useState(member.memberName)
  const [memberNameFurigana, setUserNameFurigana] = useState(
    member.memberNameFurigana || '',
  )
  const [positionName, setPositionName] = useState(
    member.position?.positionName,
  )
  const [singleWord, setSingleWord] = useState(member.singleWord)
  const [image, setImage] = useState(member.profileImageUrl)
  const [isLoading, setIsLoading] = useState(false)

  const update = () => {
    setIsLoading(true)
    MemberControllerService.updateMember({
      memberName: memberName,
      memberNameFurigana: memberNameFurigana,
      positionName: positionName,
      singleWord: singleWord,
      profileImageUrl: image,
    } as MemberUpdateForm)
      .then(() => {
        closeModal()
        toast.success('プロフィールを更新しました')
      })
      .catch(err => {
        apiErrorHandler(err)
        setIsLoading(false)
      })
  }

  const changeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value)
  }

  const changeUserNameFurigana = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUserNameFurigana(event.target.value)
  }

  const changePositionName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPositionName(event.target.value)
  }

  const changeSingleWord = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // 100文字制限
    if (event.target.value.length > 100) {
      return
    }
    setSingleWord(event.target.value)
  }

  return (
    <div className="flex fixed flex-col justify-start top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[620px] max-w-full max-h-[90%] rounded-lg bg-white p-6">
      {isLoading ? (
        <Loading className="loading" type="spin" color="#007559" />
      ) : (
        <>
          <div className="relative flex items-center h-[112px] px-8 py-6 bg-gradient-to-t from-[rgba(8,10,7,0.45)] to-[rgba(8,10,7,0.45)] gap-4 md:max-h-[92px] md:px-4 md:py-3">
            <img
              className="absolute inset-0 w-full h-full object-cover z-[-1]"
              src="/images/bg_profile_edit.jpeg"
              alt="プロフィール背景画像"
            />
            <div
              className="relative w-16 h-16 cursor-pointer rounded-full"
              onClick={() => openModal(ImageUploadModal, { image, setImage })}
            >
              <img
                src={image ? image : '/icons/avatar-sample.png'}
                alt="プロフィール画像"
                className="w-full h-full rounded-full object-cover"
              />
              <div className="absolute right-0 bottom-0 z-10 w-[22px] h-[22px] rounded-full bg-gray-200"></div>
              <svg
                className="absolute right-1 bottom-[3px] z-20"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2583 5.2056L8.77917 2.7556L9.59584 1.93893C9.81945 1.71532 10.0942 1.60352 10.4201 1.60352C10.746 1.60352 11.0205 1.71532 11.2438 1.93893L12.0604 2.7556C12.284 2.97921 12.4007 3.2491 12.4104 3.56527C12.4201 3.88143 12.3132 4.15113 12.0896 4.37435L11.2583 5.2056ZM2.33334 12.2493C2.16806 12.2493 2.02942 12.1933 1.91742 12.0813C1.80542 11.9693 1.74961 11.8309 1.75 11.666V10.0181C1.75 9.94032 1.76459 9.86488 1.79375 9.79177C1.82292 9.71866 1.86667 9.65313 1.925 9.59518L7.93334 3.58685L10.4125 6.06602L4.40417 12.0744C4.34584 12.1327 4.28031 12.1764 4.20759 12.2056C4.13486 12.2348 4.05942 12.2493 3.98125 12.2493H2.33334Z"
                  fill="#212643"
                />
              </svg>
            </div>
            {/*
        <button type="button" className="profile-edit__btn">
          カバー画像を変更
        </button>
        */}

            <Button
              className="block absolute top-6 right-6 w-6 h-6 md:top-4 md:right-4"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                aria-label="Close modal"
              >
                <rect width="24" height="24" rx="12" fill="#EDF0F7" />
                <path
                  d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                  fill="#8A8F9F"
                />
              </svg>
            </Button>
          </div>

          <div className="mb-16 py-4">
            <dl className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <dt className="text-gray-900 font-bold text-sm">お名前</dt>
                <dd className="overflow-hidden rounded-xl bg-[#edf0f7]">
                  <input
                    type="text"
                    value={memberName}
                    onChange={changeUserName}
                    className="block w-full p-3 px-4 border-none rounded-xl bg-transparent text-inherit placeholder-gray-400 disabled:text-gray-400"
                  />
                </dd>
              </div>

              <div className="flex flex-col gap-1">
                <dt className="text-gray-900 font-bold text-sm">ふりがな</dt>
                <dd className="overflow-hidden rounded-xl bg-[#edf0f7]">
                  <input
                    type="text"
                    value={memberNameFurigana}
                    onChange={changeUserNameFurigana}
                    className="block w-full p-3 px-4 border-none rounded-xl bg-transparent text-inherit placeholder-gray-400 disabled:text-gray-400"
                  />
                </dd>
              </div>

              <div className="flex flex-col gap-1">
                <dt className="text-gray-900 font-bold text-sm">役職</dt>
                <dd className="overflow-hidden rounded-xl bg-[#edf0f7]">
                  <input
                    name="group"
                    placeholder="例）プロダクト企画本部 / parksグループ / UI/UXデザイナー"
                    value={positionName}
                    onChange={changePositionName}
                    className="block w-full p-3 px-4 border-none rounded-xl bg-transparent text-inherit placeholder-gray-400 disabled:text-gray-400"
                  ></input>
                </dd>
              </div>

              <div className="flex flex-col gap-1">
                <dt className="text-gray-900 font-bold text-sm">
                  ひとこと（100文字）
                </dt>

                <dd className="overflow-hidden rounded-xl bg-[#edf0f7]">
                  <textarea
                    placeholder="興味のあるトピックを教えてください"
                    maxLength={100}
                    value={singleWord}
                    onChange={changeSingleWord}
                    className="block w-full p-3 px-4 border-none rounded-xl bg-transparent text-inherit placeholder-gray-400 disabled:text-gray-400 h-20 resize-none"
                  ></textarea>
                </dd>
              </div>
            </dl>
            <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
              <Button
                className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
                onClick={closeModal}
              >
                キャンセル
              </Button>
              <Button
                className="px-6 py-3 w-32 flex justify-center items-center btn-primary-base"
                onClick={update}
              >
                保存
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
