import React, { FC } from 'react'
import Linkify from 'linkify-react'
import linkifyHtml from 'linkify-html'

interface SafeParagraphProps {
  content?: string | null
  className?: string
  dangerouslySetInnerHTML?: boolean
  cleanDangerousContent?: (dangerousContent: string) => string
}

export const SafeParagraph: FC<SafeParagraphProps> = ({
  content,
  className,
  dangerouslySetInnerHTML = false,
  cleanDangerousContent,
}) => {
  const linkifyOptions = {
    className: 'link',
    attributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  }

  const linkifyStyle = { whiteSpace: 'pre-wrap' as const }

  const cleanContent = (dangerousContent: string | null | undefined) => {
    if (!dangerousContent) return ''
    return cleanDangerousContent?.(dangerousContent) || dangerousContent
  }

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  return dangerouslySetInnerHTML ? (
    <p
      className={className}
      dangerouslySetInnerHTML={{
        __html: linkifyHtml(cleanContent(content), linkifyOptions),
      }}
      style={linkifyStyle}
      onClick={onClick}
    />
  ) : (
    <Linkify
      as="p"
      className={className}
      options={linkifyOptions}
      style={linkifyStyle}
      onClick={onClick}
    >
      {content || ''}
    </Linkify>
  )
}
