import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MemberQuery } from '../../services'
import { pickupCubes } from '../../lib/common'
import ChatButton from '../button/chatButton'
import CubeList from './CubeList'

interface MemberCardProps {
  member: MemberQuery
  className: string
  cubeListClassName: string
  cubeItemClassName: string
}

const MemberCard: React.FC<MemberCardProps> = ({
  member,
  className,
  cubeListClassName,
  cubeItemClassName,
}) => {
  const navigate = useNavigate()

  const handleMemberClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault()
    event.stopPropagation()
    navigate(`/member-detail/${member.memberId}`)
  }

  const profileImageUrl = member.profileImageUrl || '/icons/avatar-sample.png'

  return (
    <li
      className={`p-4 border border-[#edf0f7] rounded-xl shadow-[0_2px_12px_0_rgba(0,0,0,0.03),0_0_16px_0_rgba(0,0,0,0.03),0_2px_5px_0_rgba(0,0,0,0.1)] bg-white cursor-pointer ${className}`}
      onClick={handleMemberClick}
    >
      <div>
        <div className="flex justify-between items-center md:gap-[22px] gap-2">
          <div className="flex items-center gap-[7px]">
            <div className="w-10 aspect-square">
              <picture>
                <source srcSet={profileImageUrl} media="(min-width: 768px)" />
                <img
                  src={profileImageUrl}
                  alt="プロフィール画像"
                  className="w-full h-full rounded-full object-cover"
                />
              </picture>
            </div>

            <div className="flex-1">
              <div className="flex flex-col items-start gap-0.5">
                <h3 className="font-bold text-sm break-all">
                  {member.memberName}
                </h3>
                <p className="text-[#8a8f9f] text-[10px]">
                  {member.lengthOfService}
                </p>
              </div>
              <p className="mt-1 text-[#8a8f9f] text-[10px] break-all">
                {member.position?.positionName}
              </p>
            </div>
          </div>

          <div className="flex-shrink-0">
            <ChatButton
              className="w-full max-w-[135px] block px-3 py-2 rounded-lg text-[#007559] border border-[#007559] bg-white text-xs font-bold transition-all duration-300 ease-out hover:opacity-60"
              memberId={member.memberId}
            />
          </div>
        </div>

        <div className="mt-2">
          <CubeList
            cubes={pickupCubes(member.cubes)}
            className={cubeListClassName}
            itemClassName={cubeItemClassName}
          />
        </div>
      </div>
    </li>
  )
}

export default MemberCard
