import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { SideNavi } from '../../layouts/sideNavi'
import {
  NotificationCardControllerService,
  NotificationCardResultQuery,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { timeAgoInWords } from '../../lib/common'

const NOTIFICATION_CARD_LIMIT = 20

export const Notification: FC = () => {
  const navigate = useNavigate()
  const [notificationCardResult, setNotificationCardResult] =
    useState<NotificationCardResultQuery>()
  const [exclusiveStartNotificationCard, setExclusiveStartNotificationCard] =
    useState<
      | {
          exclusiveStartNotificationCardId: string
          exclusiveStartNotificationNotifyAt: string
        }
      | undefined
    >(undefined)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    getNotificationCard(undefined, NOTIFICATION_CARD_LIMIT)
    NotificationCardControllerService.readAllNotificationCards()
  }, [])

  // スクロール位置の検出
  useEffect(() => {
    const handleScroll = _.throttle(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      ) {
        return
      }
      // 追加のデータが無い場合、またはデータをフェッチ中の場合は処理を終了
      if (!exclusiveStartNotificationCard || isFetching) {
        return
      }
      getNotificationCard(
        exclusiveStartNotificationCard,
        NOTIFICATION_CARD_LIMIT,
      )
    }, 1000) // 1秒間に最大1回だけイベントハンドラが実行されるようにスロットリング

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [exclusiveStartNotificationCard, isFetching])

  const getNotificationCard = (
    exclusiveStartNotificationCard:
      | {
          exclusiveStartNotificationCardId: string
          exclusiveStartNotificationNotifyAt: string
        }
      | undefined,
    limit: number,
  ) => {
    setIsFetching(true)

    NotificationCardControllerService.getNotificationCards(
      exclusiveStartNotificationCard?.exclusiveStartNotificationCardId,
      exclusiveStartNotificationCard?.exclusiveStartNotificationNotifyAt,
      limit,
    )
      .then(res => {
        setNotificationCardResult(prev => {
          if (prev) {
            // 重複排除
            const notificationCards = res.notificationCards.filter(
              notificationCard => {
                return prev.notificationCards.every(prevNotificationCard => {
                  return (
                    notificationCard.notificationCardId !==
                    prevNotificationCard.notificationCardId
                  )
                })
              },
            )
            return {
              notificationCards: [
                ...prev.notificationCards,
                ...notificationCards,
              ],
            }
          }
          return res
        })
        if (
          res.exclusiveStartNotificationCardId !== undefined &&
          res.exclusiveStartNotificationNotifyAt !== undefined
        ) {
          setExclusiveStartNotificationCard({
            exclusiveStartNotificationCardId:
              res.exclusiveStartNotificationCardId,
            exclusiveStartNotificationNotifyAt:
              res.exclusiveStartNotificationNotifyAt,
          })
        } else {
          setExclusiveStartNotificationCard(undefined)
        }
      })
      .catch(err => {
        apiErrorHandler(err)
      })
      .finally(() => setIsFetching(false))
  }

  const getCardIconPath = (notificationCardType: string) => {
    if (notificationCardType === 'TIMELINE_COMMENT') {
      return '/icons/icon_comment.svg'
    }
    if (notificationCardType === 'TIMELINE_COMMENT_WITH_MENTION') {
      return '/icons/icon_mention.svg'
    }
    if (notificationCardType === 'TIMELINE_REACTION_GRINNING_SQUINTING_FACE') {
      return '/images/reaction-GRINNING_SQUINTING_FACE.svg'
    }
    if (notificationCardType === 'TIMELINE_REACTION_HEART') {
      return '/images/reaction-HEART.svg'
    }
    if (notificationCardType === 'TIMELINE_REACTION_LOUDLY_CRYING_FACE') {
      return '/images/reaction-LOUDLY_CRYING_FACE.svg'
    }
    if (notificationCardType === 'TIMELINE_REACTION_SMILE_FACE') {
      return '/images/reaction-SMILE_FACE.svg'
    }
    if (notificationCardType === 'TIMELINE_REACTION_THUMBS_UP') {
      return '/images/reaction-THUMBS_UP.svg'
    }
    if (notificationCardType === 'CUBE_SEND') {
      return '/icons/icon_cube.svg'
    }

    return ''
  }

  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="main-sidenav notification_page-main-sidenav">
        <ul className="notification-list">
          {notificationCardResult?.notificationCards.length === 0 && (
            <li className="notification-list_item notification-list_no-item">
              <div className="notification-list_text">
                <p>通知がありません</p>
              </div>
            </li>
          )}
          {notificationCardResult?.notificationCards.map(notificationCard => (
            <>
              <li
                className="notification-list_item"
                onClick={() => navigate(notificationCard.url)}
              >
                <div className="notification-list_icon">
                  <img
                    src={
                      notificationCard.actionMembers[0]?.profileImageUrl ||
                      '/icons/avatar-sample.png'
                    }
                    alt=""
                    width={32}
                    height={32}
                  />
                  <img
                    src={getCardIconPath(notificationCard.notificationCardType)}
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
                <div className="notification-list_text">
                  <p>{notificationCard.text}</p>
                  <p className="notification-list_time">
                    {timeAgoInWords(notificationCard.notifyAt)}
                  </p>
                </div>
              </li>
            </>
          ))}

          {/* todo アクティブな通知カードはカードごとの未読既読実装後対応 */}
          {/* <li className="notification-list_item notification-list_item-active">
            <div className="notification-list_icon">
              <img
                src="/images/home_avatar03.jpg"
                alt=""
                width={32}
                height={32}
              />
              <img src="/icons/icon_cube.svg" alt="" width={16} height={16} />
            </div>
            <div className="notification-list_text notification-list_text-active">
              <p>
                <span>加藤 はな</span>さんがあなたにキューブを送りました
              </p>
              <p className="notification-list_time">約1時間前</p>
            </div>
          </li> */}
        </ul>
      </main>
    </>
  )
}
