import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import {
  MemberControllerService,
  DeveloperControllerService,
  WorkspaceDto,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { Button } from '../../components/button'

export const Delete: FC = () => {
  const navigate = useNavigate()

  const [workspaces, setWorkspaces] = useState([] as WorkspaceDto[])

  const [memberRegistrationWorkspaceId, setMemberRegistrationWorkspaceId] =
    useState('')
  const [workspaceName, setWorkspaceName] = useState('')

  const getWorkspaces = () => {
    DeveloperControllerService.getAllWorkspaces()
      .then(setWorkspaces)
      .catch(apiErrorHandler)
  }

  const deleteWorkspace = () => {
    if (memberRegistrationWorkspaceId && workspaceName !== '') {
      // 確認モーダルを表示
      if (!window.confirm(workspaceName + 'を削除します。よろしいですか？')) {
        return
      }
      DeveloperControllerService.deleteWorkspace({
        workspaceId: memberRegistrationWorkspaceId,
        workspaceName: workspaceName,
      })
        .then(() => {
          toast.success('ワークスペースを削除しました。')
          getWorkspaces()
          setMemberRegistrationWorkspaceId('')
          setWorkspaceName('')
        })
        .catch(apiErrorHandler)
    }
  }

  useEffect(() => {
    const getLoginMember = () => {
      MemberControllerService.getMember(0)
        .then(res => {
          // rond.co.jp以外はホームへリダイレクト
          if (res.email.split('@')[1] !== 'rond.co.jp') {
            navigate('/')
          }
        })
        .catch(apiErrorHandler)
    }

    getLoginMember()
    getWorkspaces()
  }, [])

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main registration">
        <div className="form__wrapper form__wrapper--wide">
          <h1 className="pagetitle">ワークスペース削除</h1>
          <div className="form">
            <div className="form__item">
              <label htmlFor="workspace" className="form__label">
                ワークスペース
              </label>
              <select
                className="form__select video-setting__select"
                value={memberRegistrationWorkspaceId}
                onChange={e => setMemberRegistrationWorkspaceId(e.target.value)}
              >
                <option value="">選択してください</option>
                {workspaces
                  .filter(workspace => workspace.deleteFlg) // 削除フラグが立っているもののみ表示
                  .map((workspace, index) => (
                    <option key={index} value={workspace.workspaceId}>
                      {workspace.workspaceName}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form__item">
              <label className="form__label">
                ワークスペース名
                <input
                  type="text"
                  id="default-input"
                  placeholder="ワークスペース名を入力してください"
                  value={workspaceName}
                  onChange={e => setWorkspaceName(e.target.value)}
                />
              </label>
            </div>
            <p className="form__submit">
              <Button className="btn" onClick={deleteWorkspace}>
                削除
              </Button>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
