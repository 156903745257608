import React, { FC } from 'react'
import { ModalBase } from './ModalBase'
import SecondaryButton from '../components/button/SecondaryButton'

interface Props {
  closeModal: () => void
}
export const AboutCubeModal: FC<Props> = ({ closeModal }) => {
  return (
    <ModalBase title="キューブとは？" closeModal={closeModal}>
      <div className="mt-6 mb-10">
        <img
          className="block mx-auto mt-4"
          src="/images/about-cube-modal_img.png"
          alt="キューブの説明画像"
          width={272}
          height={151}
        />
        <p className="mt-6 pb-10 text-sm text-left">
          parksでは一人ひとりの個性のかけらを「キューブ」と呼んでいます。
          <br />
          あなたの興味・関心、スキル・専門知、パーソナリティをキューブとして登録しましょう。
        </p>
      </div>
      <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-6 [&>button]:w-32">
        <SecondaryButton onClick={closeModal}>閉じる</SecondaryButton>
      </div>
    </ModalBase>
  )
}
