import React from 'react'
import { TimelineCardQuery, TimelineReactionMemberQuery } from '../../services'
import CardWrapper from './CardWrapper'
import MemberProfileCard from './MemberProfileCard'
import { useNavigate } from 'react-router-dom'
interface UpdateSingleWordCardProps {
  timelineCard: TimelineCardQuery
  myTimelineReactionMemberQuery: TimelineReactionMemberQuery
  timelineCommentComponent?: JSX.Element
  onClickTimelineCard?: () => void
}

const UpdateSingleWordCard: React.FC<UpdateSingleWordCardProps> = ({
  timelineCard,
  myTimelineReactionMemberQuery,
  timelineCommentComponent,
  onClickTimelineCard,
}) => {
  const navigate = useNavigate()
  const updateSingleWordMember = timelineCard.updateSingleWordCard?.member
  if (!updateSingleWordMember) return null
  return (
    <CardWrapper
      className={'home-timeline__card home-timeline__card-comment'}
      onClick={onClickTimelineCard}
      profileImageUrl={updateSingleWordMember.profileImageUrl}
      title={
        <>
          <span
            onClick={event => {
              event.stopPropagation()
              navigate(`/member-detail/${updateSingleWordMember.memberId}`)
            }}
            className="break-words"
          >
            {updateSingleWordMember.memberName}
          </span>
          さんがひとことを更新しました
        </>
      }
      createdAt={timelineCard.createdAt}
      timelineCard={timelineCard}
      myTimelineReactionMemberQuery={myTimelineReactionMemberQuery}
      member={updateSingleWordMember}
      timelineCommentComponent={timelineCommentComponent}
    >
      <MemberProfileCard
        member={updateSingleWordMember}
        singleWord={timelineCard.updateSingleWordCard?.singleWord}
        className="home-timeline__card-under-member"
        onClickTimelineCard={onClickTimelineCard}
      />
    </CardWrapper>
  )
}

export default UpdateSingleWordCard
