import React from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
  closeModal: () => void
}

export const DataOptionsModal: React.FC<Props> = ({ closeModal }) => {
  const handleNavLinkClick = () => {
    closeModal()
  }
  return (
    <div className="fixed bottom-20 w-80 max-w-full p-6 overflow-hidden rounded-lg bg-white left-[calc(50%-150px)]">
      <ul className="text-[14px] font-bold">
        {[
          {
            to: '/cube-summary',
            text: 'サマリー',
            icon: '/images/icon_data.svg',
          },
          {
            to: '/ranking',
            text: 'ランキング',
            icon: '/images/icon_ranking.svg',
          },
          {
            to: '/member-list',
            text: 'メンバー',
            icon: '/images/icon_nav_member.svg',
          },
        ].map((item, index) => (
          <li key={index}>
            <NavLink
              to={item.to}
              className="block opacity-60 p-1.5 text-[#16171a] text-[12px]"
              onClick={handleNavLinkClick}
            >
              <img src={item.icon} alt="icon" className="inline-block mr-1.5" />
              {item.text}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
