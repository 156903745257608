import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import {
  AdminControllerService,
  CubeControllerService,
  CubeQuery,
  MemberControllerService,
  MemberEntity,
  MemberRoleQuery,
} from '../../services'
import { toast } from 'react-toastify'
import apiErrorHandler from '../../api/apiErrorHandler'

export const RecommendEmailFromCube: FC = () => {
  const navigate = useNavigate()
  const [cubeId, setCubeId] = useState<number | undefined>()
  const [cubes, setCubes] = useState<CubeQuery[]>([])
  const [members, setMembers] = useState([] as MemberEntity[])

  const sendEmail = () => {
    if (cubeId) {
      // 確認モーダルを表示
      if (!window.confirm('メールを送信します。よろしいですか？')) {
        return
      }
      AdminControllerService.sendRecommendEmailFromCube(cubeId)
        .then(() => {
          toast.success('メールを送信しました。')
        })
        .catch(err => {
          console.log(err)
          toast.error('メール送信に失敗しました。')
        })
    }
  }

  useEffect(() => {
    // ログインユーザーが管理者でない場合、ホームへリダイレクト
    MemberControllerService.getMemberRole().then(res => {
      if (res.memberRole !== MemberRoleQuery.memberRole.ADMIN) {
        navigate('/')
      } else {
        CubeControllerService.getCubes().then(setCubes)
      }
    })
  }, [])

  useEffect(() => {
    if (!cubeId) return
    AdminControllerService.getMemberByCubeId(cubeId)
      .then(setMembers)
      .catch(apiErrorHandler)
  }, [cubeId])

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main registration">
        <div className="form__wrapper form__wrapper--wide">
          <h1 className="pagetitle">紹介メール送信</h1>
          <div className="form">
            <div className="form__item">
              <label htmlFor="workspace" className="form__label">
                キューブ
              </label>
              <select
                className="form__select video-setting__select"
                value={cubeId}
                onChange={e => setCubeId(Number(e.target.value))}
              >
                <option value="">選択してください</option>
                {cubes.map((cube, index) => (
                  <option key={index} value={cube.cubeId}>
                    {cube.cubeName}
                  </option>
                ))}
              </select>
            </div>
            <ul className="search_input-page__list">
              {members.map(member => (
                <li key={member.memberId}>
                  <div className="card_list-m" style={{ cursor: 'default' }}>
                    <div className="card_list-m__head">
                      <div className="card_list-m__profile-wrapper">
                        <div className="card_list-m_avatar">
                          <picture>
                            <img
                              src={
                                member.profileImageUrl ??
                                '/icons/avatar-sample.png'
                              }
                              alt="プロフィール画像"
                            />
                          </picture>
                        </div>

                        <div className="card_list-m__profile">
                          <div className="card_list-m_name__wrapper">
                            <h3 className="card_list-m_name">
                              {member.memberName}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <p className="form__submit">
              <button type="button" className="btn" onClick={sendEmail}>
                メール送信
              </button>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
