import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { Link, useNavigate } from 'react-router-dom'
import { SuggestTextInput } from '../../components/suggestTextInput'
import {
  CubeControllerService,
  MemberControllerService,
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
  MemberQuery,
} from '../../services'
import { toast } from 'react-toastify'
import apiErrorHandler from '../../api/apiErrorHandler'
import Loading from 'react-loading'
import { Button } from '../../components/button'
import { useModal } from '../../contexts/modalContext'
import { AboutCubeModal } from '../../modals/AboutCubeModal'
import { CubeCategoryModal } from '../../modals/CubeCategoryModal'

export const SignupCube: FC = () => {
  const navigate = useNavigate()
  const { openModal } = useModal()
  const [isLoading, setIsLoading] = useState(false)
  const [member, setMember] = useState<MemberQuery>()
  const [inputCubeMap, setInputCubeMap] = useState<Map<number, string>>(
    new Map(),
  )
  const [cubeMap, setCubeMap] = useState<Map<number, string[]>>(new Map())

  useEffect(() => {
    MemberControllerService.getMember(0).then(setMember).catch(apiErrorHandler)
  }, [openModal])

  // キューブ登録
  const createCubes = () => {
    const memberCubeForms = Array.from(cubeMap)
      .map(([cubeCategoryId, cubes]) => {
        return cubes.map(cubeName => {
          return {
            memberId: member?.memberId,
            cubeCategoryId: cubeCategoryId,
            cubeName: cubeName,
            giftMessage: undefined,
          } as MemberCubeForm
        })
      })
      .flat()

    if (memberCubeForms.length === 0) {
      navigate('/complete')
      return
    }
    setIsLoading(true)
    MemberCubeControllerService.createMemberCubes({
      memberCubes: memberCubeForms,
      timelineDisabled: true,
    } as MemberCubesForm)
      .then(() => {
        navigate('/complete')
        return
      })
      .catch(err => {
        toast.error('登録に失敗しました')
        apiErrorHandler(err)
        setIsLoading(false)
      })
  }

  // cube入力
  const setInput = (cubeCategoryId: number, value: string) => {
    const newInputCubeMap = new Map(inputCubeMap)
    newInputCubeMap.set(cubeCategoryId, value)
    setInputCubeMap(newInputCubeMap)
  }

  // cube追加
  const setCube = (cubeCategoryId: number, value: string) => {
    // 空文字や同名の場合は追加しない
    if (
      !value ||
      !value.match(/\S/g) ||
      cubeMap.get(cubeCategoryId)?.includes(value)
    ) {
      return
    }

    const newCubeMap = new Map(cubeMap)
    newCubeMap.set(cubeCategoryId, [
      ...(newCubeMap.get(cubeCategoryId) || []),
      value,
    ])
    setCubeMap(newCubeMap)
  }

  // cube削除
  const deleteCube = (cubeCategoryId: number, index: number) => {
    const newCubeMap = new Map(cubeMap)
    const cubes = newCubeMap.get(cubeCategoryId)
    if (cubes) {
      cubes.splice(index, 1)
      newCubeMap.set(cubeCategoryId, cubes)
      setCubeMap(newCubeMap)
    }
  }

  // サジェスト候補を取得
  const getSuggests = async (value: string): Promise<string[]> => {
    return await CubeControllerService.suggestCubes(value)
      .then(res => {
        return res.map(cube => cube.cubeName)
      })
      .catch(err => {
        apiErrorHandler(err)
        return []
      })
  }
  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main signup_cube">
        {isLoading ? (
          <Loading className="loading" type="spin" color="#007559" />
        ) : (
          <>
            <div className="pagetitle__wrapper">
              <h1 className="pagetitle pagetitle--large">
                キューブを登録しましょう💪
              </h1>
              <p className="pagetitle__description">
                キューブをたくさん登録してあなたのことを表現しましょう
              </p>
              <p
                className="pagetitle__info signup_cube__info"
                onClick={() => {
                  openModal(AboutCubeModal, {})
                }}
              >
                キューブとは
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM9 13.5C8.83313 13.5 8.66999 13.4505 8.53124 13.3578C8.39249 13.2651 8.28434 13.1333 8.22048 12.9791C8.15662 12.825 8.13991 12.6553 8.17247 12.4916C8.20502 12.328 8.28538 12.1776 8.40338 12.0596C8.52138 11.9416 8.67172 11.8613 8.8354 11.8287C8.99907 11.7962 9.16872 11.8129 9.32289 11.8767C9.47707 11.9406 9.60884 12.0487 9.70156 12.1875C9.79427 12.3262 9.84375 12.4894 9.84375 12.6562C9.84375 12.88 9.75486 13.0946 9.59662 13.2529C9.43839 13.4111 9.22378 13.5 9 13.5ZM9.5625 10.0744V10.125C9.5625 10.2742 9.50324 10.4173 9.39775 10.5227C9.29226 10.6282 9.14919 10.6875 9 10.6875C8.85082 10.6875 8.70775 10.6282 8.60226 10.5227C8.49677 10.4173 8.4375 10.2742 8.4375 10.125V9.5625C8.4375 9.41332 8.49677 9.27024 8.60226 9.16475C8.70775 9.05926 8.85082 9 9 9C9.93024 9 10.6875 8.36719 10.6875 7.59375C10.6875 6.82031 9.93024 6.1875 9 6.1875C8.06977 6.1875 7.3125 6.82031 7.3125 7.59375V7.875C7.3125 8.02418 7.25324 8.16726 7.14775 8.27275C7.04226 8.37824 6.89919 8.4375 6.75 8.4375C6.60082 8.4375 6.45775 8.37824 6.35226 8.27275C6.24677 8.16726 6.1875 8.02418 6.1875 7.875V7.59375C6.1875 6.19805 7.44891 5.0625 9 5.0625C10.5511 5.0625 11.8125 6.19805 11.8125 7.59375C11.8125 8.81578 10.845 9.83883 9.5625 10.0744Z"
                    fill="#007559"
                  />
                </svg>
              </p>
            </div>
            <div className="form__wrapper form__wrapper--wide">
              <div className="form form--wide">
                {member?.cubeCategories.map((cubeCategory, index) => (
                  <React.Fragment key={index}>
                    <label
                      className={`form__label ${
                        cubeCategory.defaultFlg ? '' : 'form__label-edit'
                      }`}
                      onClick={() => {
                        if (cubeCategory.defaultFlg) {
                          return
                        }
                        openModal(CubeCategoryModal, {
                          cubeCategoryId: cubeCategory.cubeCategoryId,
                          cubeCategories: member?.cubeCategories,
                        })
                      }}
                    >
                      {cubeCategory.cubeCategoryName}
                    </label>
                    <div className="cube_input__block">
                      <SuggestTextInput
                        className="cube_input"
                        placeholder="キューブを入力"
                        value={
                          inputCubeMap.get(cubeCategory.cubeCategoryId) || ''
                        }
                        setValue={value =>
                          setInput(cubeCategory.cubeCategoryId, value)
                        }
                        onSelected={value => {
                          setCube(cubeCategory.cubeCategoryId, value)
                        }}
                        getSuggests={getSuggests}
                      />
                    </div>
                    {Array.from(cubeMap.entries())
                      .filter(
                        ([cubeCategoryId]) =>
                          cubeCategoryId === cubeCategory.cubeCategoryId,
                      )
                      .map(
                        ([cubeCategoryId, cubes]) =>
                          cubes.length > 0 && (
                            <div className="form__item" key={cubeCategoryId}>
                              <p className="signup_cube__recommend">
                                登録済みのキューブ
                              </p>
                              <ul className="cube-list signup_cube__recommend-list">
                                {cubes.map((cubeName, index) => (
                                  <li className="cube" key={index}>
                                    {cubeName}
                                    <Button
                                      className="cube__delete-btn"
                                      onClick={() =>
                                        deleteCube(cubeCategoryId, index)
                                      }
                                    />
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ),
                      )}
                  </React.Fragment>
                ))}

                <div className="form__item">
                  <p className="signup_cube__category-info">
                    「興味・関心」「スキル・専門」「パーソナリティ」以外にも自分でカテゴリーを追加することができます
                  </p>
                  <Button
                    className="btn btn__secondary btn--sp-wide"
                    onClick={() => {
                      openModal(CubeCategoryModal, {
                        cubeCategories: member?.cubeCategories,
                      })
                    }}
                  >
                    カテゴリーを追加
                  </Button>
                </div>

                <p className="form__submit">
                  <Button className="btn btn--sp-wide" onClick={createCubes}>
                    キューブを登録
                  </Button>
                </p>
                <p className="form__link form__link--green">
                  <Link to="/complete">あとで</Link>
                </p>
              </div>
            </div>
          </>
        )}
      </main>
      <Footer />
    </>
  )
}
