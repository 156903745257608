import { Button } from '.'
import React from 'react'

type Props = {
  className?: string
  children: React.ReactNode
  onClick: () => void
  disabled?: boolean
}

const PrimaryButton: React.FC<Props> = ({
  className,
  children,
  onClick,
  disabled,
}) => {
  return (
    <Button
      className={`btn-base btn-primary-base ${className}`}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        onClick()
      }}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

export default PrimaryButton
