import react from 'react'
import { MemberQuery } from '../services'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useChatRoom } from '../hooks/useChatRoom'
import { SuggestMemberCard } from '../components/suggestMemberCard'
import React from 'react'
import { ModalBase } from './ModalBase'
import SecondaryButton from '../components/button/SecondaryButton'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  closeModal: () => void
}

export const AddChatModal: react.FC<Props> = ({ closeModal }) => {
  const navigate = useNavigate()
  const [selectedMembers, setSelectedMembers] = react.useState<MemberQuery[]>(
    [],
  )
  const { addChatRoom, updateChatRoomMembers } = useChatRoom()

  const location = useLocation()
  const currentPath = location.pathname
  const [searchParams] = useSearchParams()
  const chatRoomId = searchParams.get('chatRoomId') || ''

  const onClickAddMember = () => {
    const memberIds = selectedMembers.map(x => x.memberId)
    currentPath === '/chat-list'
      ? addChatRoomAndMove(memberIds)
      : addMemberToExistingChatRoom(memberIds)
  }

  const addChatRoomAndMove = (memberIds: number[]) => {
    addChatRoom(memberIds).then(res => {
      const chatRoomId = res
      closeModal()
      navigate(`/chat-detail?chatRoomId=${chatRoomId}`)
    })
  }

  const addMemberToExistingChatRoom = (memberIds: number[]) => {
    updateChatRoomMembers(chatRoomId, memberIds).then(() => {
      const searchParams = new URLSearchParams(location.search)
      const chatRoomId = searchParams.get('chatRoomId')
      navigate(`/chat-detail?chatRoomId=${chatRoomId}&member=added`)
      closeModal()
      window.location.reload() // ページを更新する
    })
  }

  return (
    <ModalBase title="チャットを送る相手を探す" closeModal={closeModal}>
      <div className="mt-6 mb-20">
        <SuggestMemberCard setMembers={setSelectedMembers} multiSelect />
        <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-6 [&>button]:w-32">
          <SecondaryButton onClick={closeModal}>キャンセル</SecondaryButton>
          <PrimaryButton
            onClick={onClickAddMember}
            disabled={selectedMembers.length === 0}
          >
            追加する
          </PrimaryButton>
        </div>
      </div>
    </ModalBase>
  )
}
