import React, { FC, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { MemberPasswordResetControllerService } from '../../services'
import { Button } from '../../components/button'

export const PasswordResetMail: FC = () => {
  const [isSent, setIsSent] = useState(false)
  const [passwordResetMailForm, setPasswordResetMailForm] = useState({
    email: '',
  })

  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessageMap, setErrorMessageMap] = useState({
    email: '',
  })

  const sendPasswordResetMail = () => {
    setErrorMessage('')
    setErrorMessageMap({ email: '' })

    MemberPasswordResetControllerService.sendResetPassword({
      email: passwordResetMailForm.email,
    })
      .then(() => {
        setIsSent(true)
      })
      .catch(err => {
        console.log(err.body)
        if (err.body.errorMessage) {
          setErrorMessage(err.body.errorMessage)
        }
        if (err.body.formErrors) {
          setErrorMessageMap(err.body.formErrors)
        }
      })
  }

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main">
        <div className="form__wrapper">
          <h1 className="pagetitle">パスワードリセット</h1>

          <hr className="separator" />

          <div>
            <small>
              パスワードをリセットするためのメールを送信します。
              メール内に記載されたURLをクリックして、パスワードをリセットしてください。
            </small>
          </div>

          <hr className="separator" />

          {!isSent && (
            <>
              {errorMessage && (
                <>
                  <p className="form__item">
                    <span className="form__error">{errorMessage}</span>
                  </p>
                  <hr className="separator" />
                </>
              )}
              {errorMessageMap && (
                <>
                  <p className="form__item">
                    {errorMessageMap.email && (
                      <>
                        <span className="form__error">
                          {errorMessageMap.email}
                        </span>
                      </>
                    )}
                  </p>
                </>
              )}

              <p className="form__item">
                <label className="form__label">
                  メールアドレス
                  <input
                    type="email"
                    name="email"
                    onChange={e =>
                      setPasswordResetMailForm({
                        ...passwordResetMailForm,
                        email: e.target.value,
                      })
                    }
                  />
                </label>
              </p>

              <br />

              <Button className="btn" onClick={sendPasswordResetMail}>
                パスワードリセットメールを送信
              </Button>
            </>
          )}
          {isSent && (
            <>
              <p
                style={{
                  textAlign: 'center',
                }}
              >
                パスワードリセットメール送信完了
              </p>
              <hr className="separator" />
            </>
          )}
        </div>
      </main>
      <Footer />
    </>
  )
}
