import { FC, useState } from 'react'
import React from 'react'
import { TextInput } from '../components/textInput'
import { CubeCategoryControllerService, CubeCategoryQuery } from '../services'
import { toast } from 'react-toastify'
import apiErrorHandler from '../api/apiErrorHandler'
import { ModalBase } from './ModalBase'
import SecondaryButton from '../components/button/SecondaryButton'
import PrimaryButton from '../components/button/PrimaryButton'
import DeleteButton from '../components/button/DeleteButton'

interface Props {
  cubeCategoryId?: number
  cubeCategories: CubeCategoryQuery[]
  closeModal: () => void
}

export const CubeCategoryModal: FC<Props> = ({
  cubeCategoryId,
  cubeCategories,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [cubeCategoryName, setCubeCategoryName] = useState<string>(
    (cubeCategoryId &&
      cubeCategories?.find(
        cubeCategory => cubeCategory.cubeCategoryId === cubeCategoryId,
      )?.cubeCategoryName) ||
      '',
  )

  const createCubeCategory = () => {
    if (
      cubeCategories.some(
        cubeCategory => cubeCategory.cubeCategoryName === cubeCategoryName,
      )
    ) {
      toast.error('既に存在するカテゴリーです')
      return
    }
    setIsLoading(true)
    CubeCategoryControllerService.createCubeCategory({
      cubeCategoryName: cubeCategoryName,
    })
      .then(() => {
        toast.success('カテゴリーを追加しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const updateCubeCategory = () => {
    if (cubeCategoryId === undefined) {
      return
    }
    if (
      cubeCategories.some(
        cubeCategory => cubeCategory.cubeCategoryName === cubeCategoryName,
      )
    ) {
      toast.error('既に存在するカテゴリーです')
      return
    }
    setIsLoading(true)
    CubeCategoryControllerService.updateCubeCategory(cubeCategoryId, {
      cubeCategoryName: cubeCategoryName,
    })
      .then(() => {
        toast.success('カテゴリーを変更しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteCubeCategory = () => {
    if (!confirm('本当に削除しますか？')) {
      return
    }
    if (cubeCategoryId === undefined) {
      return
    }
    setIsLoading(true)
    CubeCategoryControllerService.deleteCubeCategory(cubeCategoryId)
      .then(() => {
        toast.success('カテゴリーを削除しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ModalBase
      title={cubeCategoryId ? 'カテゴリーを編集' : 'カテゴリーを追加'}
      closeModal={closeModal}
      isLoading={isLoading}
    >
      <div className="mt-6 mb-20">
        <TextInput
          label="カテゴリー"
          placeholder="カテゴリーを入力"
          value={cubeCategoryName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setCubeCategoryName(event.target.value)
          }
          maxLength={64}
        />
        <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-6 [&>button]:w-32">
          {cubeCategoryId ? (
            <DeleteButton onClick={deleteCubeCategory}>削除する</DeleteButton>
          ) : (
            <SecondaryButton onClick={closeModal}>キャンセル</SecondaryButton>
          )}
          <PrimaryButton
            onClick={cubeCategoryId ? updateCubeCategory : createCubeCategory}
            disabled={!cubeCategoryName}
          >
            保存する
          </PrimaryButton>
        </div>
      </div>
    </ModalBase>
  )
}
