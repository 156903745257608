import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import {
  MemberControllerService,
  DeveloperControllerService,
  WorkspaceDto,
  MemberRoleQuery,
  CommonControllerService,
  MemberRoleMap,
  PairStringString,
  AdminControllerService,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { Button } from '../../components/button'

export const Registration: FC = () => {
  const navigate = useNavigate()

  const [memberRoleMaps, setMemberRoleMaps] = useState<MemberRoleMap[]>([])
  const [invitationExpirations, setInvitationExpirations] = useState<
    PairStringString[]
  >([])

  const [workspaces, setWorkspaces] = useState([] as WorkspaceDto[])

  const [workspaceName, setWorkspaceName] = useState('')
  const [memberRegistrationWorkspaceId, setMemberRegistrationWorkspaceId] =
    useState('')
  const [invitationWorkspaceId, setInvitationWorkspaceId] = useState('')

  const [memberRole, setMemberRole] = useState<MemberRoleQuery.memberRole>(
    MemberRoleQuery.memberRole.MEMBER,
  )
  const [invitationExpiration, setInvitationExpiration] = useState<string>('')

  const [tsv, setTsv] = useState('')

  const getWorkspaces = () => {
    DeveloperControllerService.getAllWorkspaces()
      .then(setWorkspaces)
      .catch(apiErrorHandler)
  }

  const createWorkspace = () => {
    if (workspaceName !== '') {
      // 確認モーダルを表示
      if (!window.confirm(workspaceName + 'を作成します。よろしいですか？')) {
        return
      }
      DeveloperControllerService.createWorkspace({ workspaceName })
        .then(() => {
          toast.success('ワークスペースを作成しました。')
          getWorkspaces()
        })
        .catch(err => {
          console.log(err)
          toast.error('ワークスペースの作成に失敗しました。')
        })
    }
  }

  const createMembers = () => {
    if (memberRegistrationWorkspaceId !== '' && tsv !== '') {
      const workspaceName = workspaces.find(
        workspace => workspace.workspaceId === memberRegistrationWorkspaceId,
      )?.workspaceName
      // 確認モーダルを表示
      if (
        !window.confirm(
          workspaceName + 'にメンバーを登録します。よろしいですか？',
        )
      ) {
        return
      }
      const inputElement = document.getElementById('file') as HTMLInputElement
      DeveloperControllerService.createMembersFromTsv(
        memberRegistrationWorkspaceId,
        {
          // @ts-ignore
          file: inputElement.files?.[0],
        },
      )
        .then(res => {
          if (res === 'OK') {
            toast.success('メンバーを登録しました。')
          } else {
            alert(res)
          }
        })
        .catch(err => {
          alert(err)
          toast.error('メンバーの登録に失敗しました。')
        })
    }
  }

  const sendEmail = () => {
    if (memberRegistrationWorkspaceId !== '' && tsv !== '') {
      const workspaceName = workspaces.find(
        workspace => workspace.workspaceId === memberRegistrationWorkspaceId,
      )?.workspaceName
      // 確認モーダルを表示
      if (
        !window.confirm(
          workspaceName +
            'の対象メンバーに招待メールを送信します。よろしいですか？',
        )
      ) {
        return
      }
      const inputElement = document.getElementById('file') as HTMLInputElement
      DeveloperControllerService.sendEmail(memberRegistrationWorkspaceId, {
        // @ts-ignore
        file: inputElement.files?.[0],
      })
        .then(res => {
          if (res === 'OK') {
            toast.success('招待メールを送信しました。')
          } else {
            alert(res)
          }
        })
        .catch(err => {
          alert(err)
          toast.error('招待メールを送信に失敗しました。')
        })
    }
  }

  const createInviteUrlAndCopy = () => {
    DeveloperControllerService.createSpecifiedWorkspaceInvitationUrl(
      invitationWorkspaceId,
      {
        role: memberRole,
        invitationExpiration: invitationExpiration,
      },
    )
      .then(res => {
        // 非同期処理だとSafariでコピーできないため、setTimeoutで同期的に処理する
        new Promise((resolve, reject) => {
          setTimeout(() => {
            navigator.clipboard.writeText(res).then(resolve).catch(reject)
          }, 0)
        })
        toast.success('招待URLを発行してコピーしました。')
      })
      .catch(apiErrorHandler)
  }

  useEffect(() => {
    const getLoginMember = () => {
      MemberControllerService.getMember(0)
        .then(res => {
          // rond.co.jp以外はホームへリダイレクト
          if (res.email.split('@')[1] !== 'rond.co.jp') {
            navigate('/')
          }
        })
        .catch(err => {
          apiErrorHandler(err)
        })

      AdminControllerService.getWorkspaceInvitationExpirations()
        .then(res => {
          setInvitationExpiration(res[0].first)
          setInvitationExpirations(res)
        })
        .catch(err => {
          apiErrorHandler(err)
        })
    }

    const getMemberRoles = () => {
      CommonControllerService.getMemberRoles()
        .then(res => {
          setMemberRoleMaps(res)
        })
        .catch(err => {
          apiErrorHandler(err)
        })
    }

    getLoginMember()
    getWorkspaces()
    getMemberRoles()
  }, [])

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main registration">
        <div className="form__wrapper form__wrapper--wide">
          <h1 className="pagetitle">ワークスペース登録</h1>
          <div className="form">
            <div className="form__item">
              <label className="form__label">
                ワークスペース名
                <input
                  type="text"
                  id="default-input"
                  placeholder="ワークスペース名を入力してください"
                  value={workspaceName}
                  onChange={e => setWorkspaceName(e.target.value)}
                />
              </label>
            </div>
            <p className="form__submit">
              <Button className="btn" onClick={createWorkspace}>
                登録
              </Button>
            </p>
          </div>
        </div>

        <hr className="separator" />

        <div className="form__wrapper form__wrapper--wide">
          <h1 className="pagetitle">メンバー登録</h1>
          <div className="form">
            <div className="form__item">
              <label htmlFor="workspace" className="form__label">
                ワークスペース
              </label>
              <select
                className="form__select video-setting__select"
                value={memberRegistrationWorkspaceId}
                onChange={e => setMemberRegistrationWorkspaceId(e.target.value)}
              >
                <option value="">選択してください</option>
                {workspaces.map((workspace, index) => (
                  <option key={index} value={workspace.workspaceId}>
                    {workspace.workspaceName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form__item">
              <label className="form__label">
                TSV
                <input
                  id="file"
                  type="file"
                  accept=".tsv"
                  onChange={e => setTsv(e.target.value)}
                />
              </label>
            </div>
            <p className="form__submit">
              <Button className="btn" onClick={createMembers}>
                登録・招待メール送信
              </Button>
            </p>
            <p className="form__submit">
              <Button className="btn" onClick={sendEmail}>
                招待メール送信のみ
              </Button>
            </p>
          </div>
        </div>

        <hr className="separator" />

        <div className="form__wrapper form__wrapper--wide">
          <h1 className="pagetitle">メンバー招待URL発行</h1>
          <div className="form">
            <div className="form__item">
              <label htmlFor="workspace" className="form__label">
                ワークスペース
              </label>
              <select
                className="form__select video-setting__select"
                value={invitationWorkspaceId}
                onChange={e => setInvitationWorkspaceId(e.target.value)}
              >
                <option value="">選択してください</option>
                {workspaces.map((workspace, index) => (
                  <option key={index} value={workspace.workspaceId}>
                    {workspace.workspaceName}
                  </option>
                ))}
              </select>
            </div>

            <div className="form__item">
              <label className="form__label">権限</label>
              <div className="form__select-wrapper">
                <select
                  className="form__select form__role-select"
                  value={memberRole}
                  onChange={e =>
                    setMemberRole(e.target.value as MemberRoleQuery.memberRole)
                  }
                >
                  {memberRoleMaps.map((memberRoleMap, index) => (
                    <option key={index} value={memberRoleMap.name}>
                      {memberRoleMap.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form__item">
              <label className="form__label">有効期限</label>
              <div className="form__select-wrapper">
                <select
                  className="form__select form__role-select"
                  value={invitationExpiration}
                  onChange={e =>
                    setInvitationExpiration(e.target.value as string)
                  }
                >
                  {invitationExpirations.map((expiration, index) => (
                    <option key={index} value={expiration.first}>
                      {expiration.second}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <p className="form__submit">
              <Button className="btn" onClick={createInviteUrlAndCopy}>
                招待URL発行してコピー
              </Button>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
