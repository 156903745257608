import { FC, useState } from 'react'
import TabSwitcher from '../components/tab/tabSwitcher'
import { QuestionForm } from '../components/question/questionForm'
import { SingleWordForm } from '../components/SingleWordInput/singleWordForm'
import { AnnounceForm } from '../components/announce/AnnounceForm'
import { SendCubeForm } from '../components/cube/sendCubeForm'
import { ModalBase } from './ModalBase'

interface Props {
  closeModal: () => void
  activeTabName: string
}

export const PostModal: FC<Props> = ({ closeModal, activeTabName }) => {
  const [isLoading, setIsLoading] = useState(false)

  // タブでモーダルコンテンツを切り替え
  const [activeTab, setActiveTab] = useState(activeTabName ?? 'sendCube')
  const tabs = [
    { key: 'sendCube', label: 'キューブを送る' },
    { key: 'singleWord', label: 'ひとことを投稿する' },
    { key: 'announce', label: '告知する' },
    { key: 'question', label: '質問する' },
  ]

  return (
    <ModalBase title="" closeModal={closeModal} isLoading={isLoading}>
      <div className="flex flex-col">
        <div className="col-span-3 justify-center">
          <TabSwitcher
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
          />
        </div>
        <div className="pr-6">
          {activeTab === 'sendCube' && (
            <SendCubeForm closeModal={closeModal} setIsLoading={setIsLoading} />
          )}
          {activeTab === 'singleWord' && (
            <SingleWordForm
              closeModal={closeModal}
              setIsLoading={setIsLoading}
            />
          )}
          {activeTab === 'announce' && (
            <AnnounceForm closeModal={closeModal} setIsLoading={setIsLoading} />
          )}
          {activeTab === 'question' && (
            <QuestionForm closeModal={closeModal} setIsLoading={setIsLoading} />
          )}
        </div>
      </div>
    </ModalBase>
  )
}
