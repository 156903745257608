import React from 'react'
import SecondaryButton from './SecondaryButton'

type Props = {
  className?: string
  children: React.ReactNode
  onClick: () => void
  disabled?: boolean
}

const DeleteButton: React.FC<Props> = ({
  className,
  children,
  onClick,
  disabled,
}) => {
  return (
    <SecondaryButton
      className={`border-red-600 text-red-600 ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </SecondaryButton>
  )
}

export default DeleteButton
