import { FC, useState } from 'react'
import {
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
} from '../services'
import React from 'react'
import apiErrorHandler from '../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { ModalBase } from './ModalBase'
import SecondaryButton from '../components/button/SecondaryButton'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  memberId: number
  cubeCategoryId: number
  cubeName: string
  closeModal: () => void
}

export const GiftMessageAddModal: FC<Props> = ({
  memberId,
  cubeCategoryId,
  cubeName,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [giftMessage, setGiftMessage] = useState<string>('')

  // キューブ登録
  const createCubes = () => {
    if (!giftMessage) {
      return
    }
    const memberCubeForms = [
      {
        memberId: memberId,
        cubeCategoryId: cubeCategoryId,
        cubeName: cubeName,
        giftMessage: giftMessage,
      } as MemberCubeForm,
    ]
    setIsLoading(true)
    MemberCubeControllerService.createMemberCubes({
      memberCubes: memberCubeForms,
    } as MemberCubesForm)
      .then(() => {
        toast.success('登録しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ModalBase
      title="キューブについて"
      closeModal={closeModal}
      isLoading={isLoading}
    >
      <div className="mt-6 mb-20">
        <div className="rounded-xl bg-gray-100">
          <textarea
            value={giftMessage}
            placeholder="きっかけや理由などをコメントしましょう！"
            onChange={e => setGiftMessage(e.target.value)}
            className="block w-full h-40 p-3 border-none bg-transparent text-inherit resize-none"
          ></textarea>
        </div>
        <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-6 [&>button]:w-32">
          <SecondaryButton onClick={closeModal}>キャンセル</SecondaryButton>
          <PrimaryButton onClick={createCubes} disabled={!giftMessage}>
            追加する
          </PrimaryButton>
        </div>
      </div>
    </ModalBase>
  )
}
