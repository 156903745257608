import React, { FC, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { MemberPasswordResetControllerService } from '../../services'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/button'

export const PasswordReset: FC = () => {
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)

  const [passwordResetForm, setPasswordResetForm] = useState({
    password: '',
    passwordConfirm: '',
  })

  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessageMap, setErrorMessageMap] = useState({
    password: '',
    passwordConfirm: '',
    samePassword: '',
  })

  const resetPassword = () => {
    setErrorMessage('')
    setErrorMessageMap({
      password: '',
      passwordConfirm: '',
      samePassword: '',
    })

    const passwordResetToken = queryParams.get('passwordResetToken') as string

    MemberPasswordResetControllerService.resetPassword({
      password: passwordResetForm.password,
      passwordConfirm: passwordResetForm.passwordConfirm,
      passwordResetToken: passwordResetToken,
    })
      .then(() => {
        navigate('/login/mail?passwordResetResult=success')
      })
      .catch(err => {
        if (err.body.errorMessage) {
          setErrorMessage(err.body.errorMessage)
        }
        if (err.body.formErrors) {
          setErrorMessageMap(err.body.formErrors)
        }
      })
  }

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main">
        <div className="form__wrapper">
          <h1 className="pagetitle">パスワードリセット</h1>

          <hr className="separator" />

          <div>
            <small>
              新しいパスワードを入力してパスワードリセットを行ってください。
            </small>
          </div>

          <hr className="separator" />

          {errorMessage && (
            <>
              <p className="form__item">
                <span className="form__error">{errorMessage}</span>
              </p>
              <hr className="separator" />
            </>
          )}
          {errorMessageMap && (
            <>
              <p className="form__item">
                {errorMessageMap.password && (
                  <>
                    <span className="form__error">
                      {errorMessageMap.password}
                    </span>
                  </>
                )}
                {errorMessageMap.passwordConfirm && (
                  <>
                    <span className="form__error">
                      {errorMessageMap.passwordConfirm}
                    </span>
                  </>
                )}
                {errorMessageMap.samePassword && (
                  <>
                    <span className="form__error">
                      {errorMessageMap.samePassword}
                    </span>
                  </>
                )}
              </p>
            </>
          )}

          <p className="form__item">
            <label className="form__label">
              パスワード(8~20文字以内・英数字必須)
              <input
                type="password"
                name="password"
                onChange={e =>
                  setPasswordResetForm({
                    ...passwordResetForm,
                    password: e.target.value,
                  })
                }
              />
            </label>
          </p>

          <p className="form__item">
            <label className="form__label">
              確認用パスワード
              <input
                type="password"
                name="password"
                onChange={e =>
                  setPasswordResetForm({
                    ...passwordResetForm,
                    passwordConfirm: e.target.value,
                  })
                }
              />
            </label>
          </p>

          <br />

          <Button className="btn" onClick={resetPassword}>
            パスワードリセット
          </Button>
        </div>
      </main>
      <Footer />
    </>
  )
}
