import React, { FC, useState } from 'react'
import {
  MemberControllerService,
  MemberQuery,
  MemberUpdateForm,
} from '../services'
import apiErrorHandler from '../api/apiErrorHandler'
import { ModalBase } from './ModalBase'
import SecondaryButton from '../components/button/SecondaryButton'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  member: MemberQuery
  closeModal: () => void
}

export const BusinessContentEditModal: FC<Props> = ({ member, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [businessContent, setBusinessContent] = useState(member.businessContent)
  const update = () => {
    setIsLoading(true)
    MemberControllerService.updateMember({
      businessContent: businessContent,
    } as MemberUpdateForm)
      .then(() => {
        closeModal()
      })
      .catch(err => {
        apiErrorHandler(err)
        setIsLoading(false)
      })
  }

  const changeBusinessContent = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setBusinessContent(event.target.value)
  }

  return (
    <ModalBase
      title="主な業務内容"
      closeModal={closeModal}
      isLoading={isLoading}
    >
      <div className="mt-6 mb-20">
        <div className="rounded-xl bg-gray-100">
          <textarea
            value={businessContent}
            placeholder="主な業務内容を入力"
            onChange={changeBusinessContent}
            className="block w-full h-40 p-3 border-none bg-transparent text-inherit resize-none"
          ></textarea>
        </div>
        <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-6 [&>button]:w-32">
          <SecondaryButton onClick={closeModal}>キャンセル</SecondaryButton>
          <PrimaryButton onClick={update}>追加する</PrimaryButton>
        </div>
      </div>
    </ModalBase>
  )
}
