import React, { FC, useEffect, useState } from 'react'
import {
  AdminControllerService,
  CommonControllerService,
  CreateMembersForm,
  MemberForm,
  MemberRoleMap,
  MemberRoleQuery,
} from '../services'
import apiErrorHandler from '../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { Button } from '../components/button'
import { ModalBase } from './ModalBase'
import SecondaryButton from '../components/button/SecondaryButton'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  closeModal: () => void
}

export const MemberSaveModal: FC<Props> = ({ closeModal }) => {
  const [memberRoleMaps, setMemberRoleMaps] = useState<MemberRoleMap[]>([])

  const [inputValue, setInputValue] = useState<string>('')
  const [memberRole, setMemberRole] = useState<MemberRoleQuery.memberRole>(
    MemberRoleQuery.memberRole.MEMBER,
  )
  const [emails, setEmails] = useState<string[]>([])

  useEffect(() => {
    CommonControllerService.getMemberRoles()
      .then(res => {
        setMemberRoleMaps(res)
      })
      .catch(err => {
        apiErrorHandler(err)
      })
  }, [])

  const update = () => {
    if (emails.length === 0) return

    AdminControllerService.createMembers({
      members: emails.map(email => {
        return {
          email: email,
          memberRole: memberRole,
        } as MemberForm
      }),
    } as CreateMembersForm)
      .then(res => {
        // 1人でも登録できた場合（一部のメンバーの招待に失敗した場合）
        if (res.length !== emails.length) {
          toast.success('メンバーを招待しました')
        }
        // 登録できなかったメールアドレスが存在する場合
        if (res.length !== 0) {
          toast.warn(
            <div>
              <p>以下のメンバーの招待に失敗しました。</p>
              <p>招待済みの可能性があります。</p>
              <ul>
                {res.map(email => (
                  <li key={email}>{email}</li>
                ))}
              </ul>
            </div>,
          )
        }
        closeModal()
      })
      .catch(err => {
        apiErrorHandler(err)
      })
  }

  const deleteEmail = (index: number) => {
    emails?.splice(index, 1)
    setEmails([...emails])
  }

  const extractEmails = () => {
    // メールアドレス形式の文字列を抽出
    const newEmails = inputValue
      .split(/[,、]/) // カンマ区切りで分割
      .filter(email =>
        /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(
          email,
        ),
      ) // メールアドレス形式の文字列のみ抽出
      .map(email => email.trim()) // 前後の空白を削除
      .filter((email, index, self) => self.indexOf(email) === index) // 重複を削除
      .filter(email => !emails.includes(email)) // 既に登録されているメールアドレスを除外
    setEmails([...emails, ...newEmails])
    setInputValue('')
  }

  return (
    <ModalBase title="メンバーを招待" closeModal={closeModal}>
      <div className="mt-6 mb-16">
        <div className="mt-5">
          <label className="block text-left font-bold text-sm">
            メールアドレス
            <input
              type="email"
              name="email"
              placeholder="カンマ区切りで複数入力"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              // enterキーで入力値を抽出
              onKeyDown={e => e.keyCode === 13 && extractEmails()}
              onBlur={extractEmails}
              className="block w-full mt-1 p-3.5 rounded-xl bg-gray-100 font-normal text-base pt-3 pb-3"
            />
          </label>
        </div>
        <hr className="separator" />
        <div className="mt-5">
          <label className="block text-left font-bold text-sm">権限</label>
          <div className="relative inline-flex items-center">
            <select
              className="appearance-none w-full py-2 px-4 pr-10 rounded-xl bg-gray-100 font-normal text-base cursor-pointer text-center"
              value={memberRole}
              onChange={e =>
                setMemberRole(e.target.value as MemberRoleQuery.memberRole)
              }
            >
              {memberRoleMaps.map((memberRoleMap, index) => (
                <option key={index} value={memberRoleMap.name}>
                  {memberRoleMap.label}
                </option>
              ))}
            </select>
            <span className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z"
                  fill="#8A8F9F"
                />
              </svg>
            </span>
          </div>
        </div>
        <div className="mt-5">
          <label className="block text-left font-bold text-sm mb-2">
            招待するメンバー
          </label>
          <div className="flex flex-wrap justify-start w-full min-h-[35px] gap-2 max-h-[16.625rem] overflow-y-auto">
            {emails.map((email, index) => (
              <span
                className="relative inline-block pr-6 pl-2 py-1 border border-blue-200 bg-[#e8f1fe] leading-6 cursor-pointer rounded font-bold text-sm"
                key={index}
              >
                {email}
                <Button
                  className="block absolute top-1/2 right-1 -translate-y-1/2 cursor-none"
                  onClick={() => deleteEmail(index)}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer pl-1 pt-0.5"
                  >
                    <path
                      d="M13.1977 12.5002L16.5496 9.14828C16.7087 8.98948 16.7982 8.77399 16.7984 8.54921C16.7986 8.32444 16.7095 8.10879 16.5507 7.94971C16.3919 7.79062 16.1764 7.70114 15.9516 7.70094C15.7268 7.70075 15.5112 7.78985 15.3521 7.94865L12.0002 11.3006L8.64828 7.94865C8.4892 7.78957 8.27344 7.7002 8.04846 7.7002C7.82349 7.7002 7.60773 7.78957 7.44865 7.94865C7.28957 8.10773 7.2002 8.32349 7.2002 8.54846C7.2002 8.77344 7.28957 8.9892 7.44865 9.14828L10.8006 12.5002L7.44865 15.8521C7.28957 16.0112 7.2002 16.227 7.2002 16.4519C7.2002 16.6769 7.28957 16.8927 7.44865 17.0517C7.60773 17.2108 7.82349 17.3002 8.04846 17.3002C8.27344 17.3002 8.4892 17.2108 8.64828 17.0517L12.0002 13.6998L15.3521 17.0517C15.5112 17.2108 15.727 17.3002 15.9519 17.3002C16.1769 17.3002 16.3927 17.2108 16.5517 17.0517C16.7108 16.8927 16.8002 16.6769 16.8002 16.4519C16.8002 16.227 16.7108 16.0112 16.5517 15.8521L13.1977 12.5002Z"
                      fill="#212643"
                    />
                  </svg>
                </Button>
              </span>
            ))}
          </div>
        </div>

        <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2 [&>button]:w-32">
          <SecondaryButton onClick={closeModal}>キャンセル</SecondaryButton>
          <PrimaryButton onClick={update}>招待</PrimaryButton>
        </div>
      </div>
    </ModalBase>
  )
}
