import React, { FC } from 'react'
import { ReactionQuery } from '../services'
import { ModalBase } from './ModalBase'

interface Props {
  timelineReaction: ReactionQuery
  closeModal: () => void
}
export const ReactionDetailModal: FC<Props> = ({
  timelineReaction,
  closeModal,
}) => {
  return (
    <ModalBase title="リアクションの詳細" closeModal={closeModal}>
      <div className="mt-4 md:mt-6">
        <h2 className="font-bold text-sm">リアクションしたメンバー</h2>
        {timelineReaction && timelineReaction.sentMemberMap && (
          <ul className="flex flex-wrap max-h-48 mt-6 overflow-y-auto gap-4">
            {Object.keys(timelineReaction.sentMemberMap).map(reaction =>
              timelineReaction.sentMemberMap[reaction].map(member => (
                <li key={member.memberId}>
                  <div
                    className="flex flex-col items-center cursor-pointer w-20 md:w-24 max-w-full gap-1"
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      window.location.href = `/member-detail/${member.memberId}`
                      closeModal()
                    }}
                  >
                    <div className="w-[40%] aspect-square">
                      <img
                        src={
                          member.profileImageUrl
                            ? member.profileImageUrl
                            : '/icons/avatar-sample.png'
                        }
                        alt="プロフィール画面"
                        className="w-full h-full rounded-full object-cover"
                      />
                    </div>
                    <div className="flex items-center gap-1.5">
                      <p className="font-bold text-[12px]">
                        {member.memberName}
                      </p>
                      <span className="flex justify-center items-center w-4 h-4 rounded-full border-solid border-[1px] border-[#c3e1fa]">
                        <img src={`/images/reaction-${reaction}.svg`} />
                      </span>
                    </div>
                    <p className="text-gray-500 text-[10px]">
                      {member.positionName}
                    </p>
                  </div>
                </li>
              )),
            )}
          </ul>
        )}
      </div>
    </ModalBase>
  )
}
