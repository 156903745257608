import React from 'react'
import {
  MemberCubeQuery,
  SimpleMemberQuery,
  TimelineCardQuery,
  TimelineReactionMemberQuery,
} from '../../services'
import CardWrapper from './CardWrapper'
import MemberProfileCard from './MemberProfileCard'
import { useNavigate } from 'react-router-dom'
import { Cube } from '../cube'
interface JoinCardProps {
  memberId: number
  joinMember: SimpleMemberQuery
  timelineCard: TimelineCardQuery
  myTimelineReactionMemberQuery: TimelineReactionMemberQuery
  timelineCommentComponent?: JSX.Element
  onClickTimelineCard?: () => void
}

const JoinCard: React.FC<JoinCardProps> = ({
  memberId,
  timelineCard,
  joinMember,
  myTimelineReactionMemberQuery,
  timelineCommentComponent,
  onClickTimelineCard,
}) => {
  const navigate = useNavigate()

  // キューブをカテゴリーごとにグルーピング
  const cubeMap = new Map<string, MemberCubeQuery[]>()
  timelineCard.joinCard?.linkMemberCubes?.forEach(cube => {
    const cubeCategoryName = cube.cubeCategory.cubeCategoryName
    if (cubeMap.has(cubeCategoryName)) {
      cubeMap.get(cubeCategoryName)?.push(cube)
    } else {
      cubeMap.set(cubeCategoryName, [cube])
    }
  })

  return (
    <CardWrapper
      className="home-timeline__card home-timeline__card-new-member"
      onClick={onClickTimelineCard}
      profileImageUrl={joinMember.profileImageUrl}
      title={
        <>
          <span
            className="home-timeline__card-upper-title-span"
            onClick={event => {
              event.stopPropagation()
              navigate(`/member-detail/${joinMember.memberId}`)
            }}
          >
            {joinMember.memberName}
          </span>
          さんがparksに参加しました🎉
        </>
      }
      cardText={'プロフィールを確認してみましょう！'}
      createdAt={timelineCard.createdAt}
      timelineCard={timelineCard}
      myTimelineReactionMemberQuery={myTimelineReactionMemberQuery}
      member={joinMember}
      timelineCommentComponent={timelineCommentComponent}
    >
      <div className="home-timeline__card-under-wrapper">
        <MemberProfileCard
          member={joinMember}
          onClickTimelineCard={onClickTimelineCard}
        />
        <div className="home-timeline__card-upper-content mt-4">
          {Array.from(cubeMap).map(([categoryName, cubes], index) => (
            <React.Fragment key={index}>
              <h2 className="cube__category">{categoryName}</h2>
              <ul className="cube__list">
                {cubes.map(cube => (
                  <Cube
                    memberId={joinMember.memberId}
                    cube={cube}
                    key={cube.cubeId}
                    deleteButtonEnabled={
                      cube.giftMembers[0]?.memberId === memberId
                    }
                    onMoreViewCommentClick={onClickTimelineCard}
                  />
                ))}
              </ul>
            </React.Fragment>
          ))}
        </div>
      </div>
    </CardWrapper>
  )
}

export default JoinCard
