import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AnnouncementQuery, AnnouncementControllerService } from '../../services';
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'

interface AnnounceCardProps {
  announcement: AnnouncementQuery;
  cardClassName?: string;
  memberId: number; 
  onDelete: () => void
}

const AccounceCard : React.FC<AnnounceCardProps> = ({ announcement, cardClassName="", memberId, onDelete }) => {
  const navigate = useNavigate()
  const onClickCard = () => {
    const announceDetailPath = `/timeline-detail/${announcement.timelineId}`
    navigate(announceDetailPath)
  };

  const clickDeleteIcon =(announcementId:number):void => {
    const isConfirmed = window.confirm('この告知を削除しますか？');

    if (isConfirmed) {
      AnnouncementControllerService.deleteAnnouncement(announcementId).then(() => {
        onDelete();
        toast.success('告知を削除しました')
      })
      .catch(apiErrorHandler);
    }
  }

  return (
    <div
      onClick={onClickCard}
      className={`
      relative z-index-1 flex flex-col gap-3 shadow-main cursor-pointer md:shadow-card rounded-lg p-4 h-[185px] my-2 ${announcement.isEnd ? "bg-disabled shadow-none": "bg-white"}
      ${cardClassName}`}
    >
      <div className={`flex items-center gap-2 ${announcement.isEnd && "text-[#b9bdc9]"}`}>
        <img
              src={
                announcement.member?.profileImageUrl ? announcement.member.profileImageUrl : '/icons/avatar-sample.png'
              }
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()    
                navigate(`/member-detail/${announcement.member?.memberId}`)
              }}
              alt="プロフィール画像"
              width={32}
              height={32}
              className='rounded-full mr-1'
            />
        <h2 className='text-sm line-clamp-2 mr-auto break-words whitespace-pre-wrap'>
          <span className='font-bold hover:underline '               
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()    
                navigate(`/member-detail/${announcement.member?.memberId}`)
              }} >{announcement.member?.memberName}
          </span>
          さんが告知しました。</h2>
        { memberId === announcement.member?.memberId && <img
          src={"/images/icon_trash_gray.svg"}
          alt="button-icon"
          className='w-5 h-5'
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()    
            clickDeleteIcon(announcement.announcementId)
          }}
        />}
      </div>
      <div className={`flex flex-col gap-1`}>
        <p className={`break-words whitespace-pre-wrap text-sm font-bold ${announcement.isEnd ? "text-[#b9bdc9]" : ""}`}>{announcement.title}</p>
        <p className={`w-full truncate text-xs ${announcement.isEnd ? "text-[#B9BDC9]" : "text-annotation" }`}>{announcement.description}</p>
      </div>
      <div className='w-full'>
        <ul className='flex gap-3 flex-wrap max-h-10 overflow-hidden'>
        {announcement.cubes && announcement.cubes.map((cube, index) => (
          <li key={index} className='py-1.5 px-3 bg-seaBlue-sea-10 border border-seaBlue-sea-20 rounded-lg max-w-full overflow-hidden whitespace-nowrap font-bold text-sm truncate'>{cube.cubeName}</li>
        ))}
        </ul>
      </div>
    </div>
  )
}

export default AccounceCard
