import React, { FC, useEffect, useState } from 'react'
import {
  AdminControllerService,
  CommonControllerService,
  MemberRoleMap,
  MemberRoleQuery,
  PairStringString,
} from '../services'
import apiErrorHandler from '../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { ModalBase } from './ModalBase'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  closeModal: () => void
}

export const MemberInviteForMailModal: FC<Props> = ({ closeModal }) => {
  const [memberRoleMaps, setMemberRoleMaps] = useState<MemberRoleMap[]>([])
  const [invitationExpirations, setInvitationExpirations] = useState<
    PairStringString[]
  >([])

  const [memberRole, setMemberRole] = useState<MemberRoleQuery.memberRole>(
    MemberRoleQuery.memberRole.MEMBER,
  )
  const [invitationExpiration, setInvitationExpiration] = useState<string>('')

  useEffect(() => {
    CommonControllerService.getMemberRoles()
      .then(res => {
        setMemberRoleMaps(res)
      })
      .catch(err => {
        apiErrorHandler(err)
      })

    AdminControllerService.getWorkspaceInvitationExpirations()
      .then(res => {
        setInvitationExpiration(res[0].first)
        setInvitationExpirations(res)
      })
      .catch(err => {
        apiErrorHandler(err)
      })
  }, [])

  const createInviteUrlAndCopy = () => {
    AdminControllerService.createWorkspaceInvitationUrl({
      role: memberRole,
      invitationExpiration: invitationExpiration,
    })
      .then(res => {
        // 非同期処理だとSafariでコピーできないため、setTimeoutで同期的に処理する
        new Promise((resolve, reject) => {
          setTimeout(() => {
            navigator.clipboard.writeText(res).then(resolve).catch(reject)
          }, 0)
        })
        toast.success('招待URLを発行してコピーしました。')
        closeModal()
      })
      .catch(apiErrorHandler)
  }

  return (
    <ModalBase title="招待URL発行(メールログイン)" closeModal={closeModal}>
      <div className="mt-6 mb-16">
        <div className="mt-5"></div>

        <hr className="separator" />

        <div className="mt-5">
          <label className="block text-left font-bold text-sm mb-2">権限</label>
          <div className="relative inline-flex items-center">
            <select
              className="appearance-none w-full py-2 px-4 pr-10 rounded-xl bg-gray-100 font-normal text-base cursor-pointer text-center"
              aria-label="権限を選択"
              value={memberRole}
              onChange={e =>
                setMemberRole(e.target.value as MemberRoleQuery.memberRole)
              }
            >
              {memberRoleMaps.map((memberRoleMap, index) => (
                <option key={index} value={memberRoleMap.name}>
                  {memberRoleMap.label}
                </option>
              ))}
            </select>
            <span className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z"
                  fill="#8A8F9F"
                />
              </svg>
            </span>
          </div>
        </div>

        <div className="mt-5">
          <label className="block text-left font-bold text-sm mb-2">
            有効期限
          </label>
          <div className="relative inline-flex items-center">
            <select
              className="appearance-none w-full py-2 px-4 pr-10 rounded-xl bg-gray-100 font-normal text-base cursor-pointer text-center"
              aria-label="有効期限を選択"
              value={invitationExpiration}
              onChange={e => setInvitationExpiration(e.target.value as string)}
            >
              {invitationExpirations.map((expiration, index) => (
                <option key={index} value={expiration.first}>
                  {expiration.second}
                </option>
              ))}
            </select>
            <span className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0983 8.5L6.90169 8.5C6.15069 8.5 5.73001 9.25351 6.19399 9.7676L9.29231 13.2006C9.65265 13.5998 10.3474 13.5998 10.7077 13.2006L13.806 9.7676C14.27 9.25351 13.8493 8.5 13.0983 8.5Z"
                  fill="#8A8F9F"
                />
              </svg>
            </span>
          </div>
        </div>

        <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
          <PrimaryButton onClick={createInviteUrlAndCopy}>
            招待URL発行してコピー
          </PrimaryButton>
        </div>
      </div>
    </ModalBase>
  )
}
