import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import { BasicInfoForm } from './BasicInfoForm'
import { AdditionalInfoForm } from './AdditionalInfoForm'
import { CareerForm } from './CareerForm'
import { ImageUploadForm } from './ImageUploadForm'
import { convertDateFromResponse } from '../../lib/common'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  CareerQuery,
  MemberControllerService,
  MemberQuery,
  MemberUpdateForm,
} from '../../services'

export const SignupProfile: FC = () => {
  const [member, setMember] = useState({} as MemberQuery)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [memberUpdateForm, setMemberUpdateForm] = useState(
    {} as MemberUpdateForm,
  )
  const [careers, setCareers] = useState([] as CareerQuery[])
  const [image, setImage] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    MemberControllerService.getMember(0)
      .then(res => {
        setMember(res)
        setMemberUpdateForm({
          ...res,
          dateOfBirth:
            res.dateOfBirth && convertDateFromResponse(res.dateOfBirth),
          dateOfEntry:
            res.dateOfEntry && convertDateFromResponse(res.dateOfEntry),
          createTimelineCardDisabled: true,
        })
        setImage(res.profileImageUrl || '')
        setCareers(res.careers || [])
      })
      .catch(apiErrorHandler)
  }, [])

  // ページ遷移
  const navigateToNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }
  const navigateToPreviousPage = () => {
    setCurrentPage(prevPage => prevPage - 1)
  }

  // 登録処理
  const signUp = (image?: string) => {
    memberUpdateForm.profileImageUrl = image || member.profileImageUrl
    memberUpdateForm.careers = careers
    MemberControllerService.updateMember(memberUpdateForm)
      .then(() => {
        navigate('/sign-up-cube/')
      })
      .catch(apiErrorHandler)
  }

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="signup-profile-main">
        {currentPage === 1 && (
          <BasicInfoForm
            memberUpdateForm={memberUpdateForm}
            setMemberUpdateForm={setMemberUpdateForm}
            navigateToNextPage={navigateToNextPage}
            member={member}
          />
        )}
        {currentPage === 2 && (
          <AdditionalInfoForm
            memberUpdateForm={memberUpdateForm}
            setMemberUpdateForm={setMemberUpdateForm}
            navigateToPreviousPage={navigateToPreviousPage}
            navigateToNextPage={navigateToNextPage}
          />
        )}
        {currentPage === 3 && (
          <CareerForm
            careers={careers}
            setCareers={setCareers}
            navigateToPreviousPage={navigateToPreviousPage}
            navigateToNextPage={navigateToNextPage}
          />
        )}
        {currentPage === 4 && (
          <ImageUploadForm
            image={image}
            setImage={setImage}
            navigateToPreviousPage={navigateToPreviousPage}
            signUp={signUp}
          />
        )}
      </main>
      <Footer />
    </>
  )
}
