import React, { FC, useState } from 'react'
import {
  MemberControllerService,
  MemberQuery,
  MemberUpdateForm,
} from '../services'
import apiErrorHandler from '../api/apiErrorHandler'
import { DateInput } from '../components/dateInput'
import { PublishingSettingInput } from '../components/publishingSettingInput'
import { convertDateFromResponse } from '../lib/common'
import { ModalBase } from './ModalBase'
import SecondaryButton from '../components/button/SecondaryButton'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  member: MemberQuery
  closeModal: () => void
}

export const ContactInfoEditModal: FC<Props> = ({ member, closeModal }) => {
  const [dateOfBirth, setDateOfBirth] = useState(
    member.dateOfBirth && convertDateFromResponse(member.dateOfBirth),
  )
  const [dateOfBirthShowFlg, setDateOfBirthShowFlg] = useState(
    member.dateOfBirthShowFlg,
  )
  const [dateOfEntry, setDateOfEntry] = useState(
    member.dateOfEntry && convertDateFromResponse(member.dateOfEntry),
  )

  const changeDateOfBirth = (birthday: string) => {
    setDateOfBirth(birthday)
  }

  const changeDateOfBirthShowFlg = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setDateOfBirthShowFlg(event.target.value === 'true')
  }

  const changeDateOfEntry = (dateOfEntry: string) => {
    setDateOfEntry(dateOfEntry)
  }

  const update = () => {
    MemberControllerService.updateMember({
      dateOfBirth: dateOfBirth,
      dateOfBirthShowFlg: dateOfBirthShowFlg,
      dateOfEntry: dateOfEntry,
    } as MemberUpdateForm)
      .then(() => {
        closeModal()
      })
      .catch(err => {
        apiErrorHandler(err)
      })
  }

  return (
    <ModalBase title="基本情報" closeModal={closeModal}>
      <div className="mt-6 mb-20">
        <div>
          <DateInput
            label="生年月日"
            date={dateOfBirth}
            onChange={changeDateOfBirth}
          />
        </div>
        <div className="mt-3.5 flex items-center gap-4">
          <PublishingSettingInput
            publicFlg={dateOfBirthShowFlg}
            onChange={changeDateOfBirthShowFlg}
          />
        </div>
        <div className="mt-5">
          <DateInput
            label="入社日"
            date={dateOfEntry}
            onChange={changeDateOfEntry}
          />
        </div>
        <p className="mt-5">
          <label className="block text-left font-bold text-sm">
            メールアドレス
            <input
              type="email"
              name="email"
              placeholder={member.email}
              disabled
              className="block w-full mt-1 p-3.5 rounded-xl bg-gray-100 font-normal text-base pt-3 pb-3"
            />
          </label>
        </p>
        <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-6 [&>button]:w-32">
          <SecondaryButton onClick={closeModal}>キャンセル</SecondaryButton>
          <PrimaryButton onClick={update}>保存</PrimaryButton>
        </div>
      </div>
    </ModalBase>
  )
}
