import React, { FC, useState } from 'react'
import { toast } from 'react-toastify'
import apiErrorHandler from '../api/apiErrorHandler'
import {
  CubeControllerService,
  CubeQuery,
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
} from '../services'
import Loading from 'react-loading'
import { Button } from '../components/button'
import { ModalBase } from './ModalBase'
import SecondaryButton from '../components/button/SecondaryButton'
import PrimaryButton from '../components/button/PrimaryButton'

interface Props {
  memberId: number
  closeModal: () => void
}
export const CubeExtractModal: FC<Props> = ({ memberId, closeModal }) => {
  const [cubeExtractStatus, setCubeExtractStatus] = useState(
    '' as 'extracting' | 'extracted' | 'creating' | '',
  )
  const [fileName, setFileName] = useState('')
  const [extractedCubes, setExtractedCubes] = useState([] as CubeQuery[])
  const [checkedCubes, setCheckedCubes] = useState([] as CubeQuery[])

  // キューブ抽出
  const createCubeExtractModal = () => {
    // ファイル選択ボタンがクリックされたときの処理
    const fileSelect = () => {
      const inputElement = document.getElementById('file') as HTMLInputElement
      inputElement.value = ''
      setFileName('')
      inputElement.click()
    }

    // ファイルが選択されたときの処理
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      setFileName(file?.name || '')
    }

    const isFileNameEmpty = fileName === ''
    // キューブ抽出処理
    const extract = () => {
      // ファイルが選択されていない場合は何もしない
      if (isFileNameEmpty) {
        return
      }
      setCubeExtractStatus('extracting')

      const inputElement = document.getElementById('file') as HTMLInputElement
      CubeControllerService.extractCubes({
        // @ts-ignore
        file: inputElement.files?.[0],
      })
        .then(res => {
          if (!res || !res.length) {
            toast.warning('有効なデータが見つかりませんでした')
            setCubeExtractStatus('')
          } else {
            setExtractedCubes(res)
            setCheckedCubes(res)
            setCubeExtractStatus('extracted')
          }
        })
        .catch(err => {
          toast.error('抽出に失敗しました')
          setCubeExtractStatus('')
          apiErrorHandler(err)
        })
    }

    return (
      <ModalBase title="キューブを自動抽出" closeModal={closeModal}>
        <p className="mt-10 text-gray-500 text-sm text-left">
          ご自身のことがわかる書類をアップロードすると、自動でキューブを抽出します。
          <br />
          書類の一例：
          <br />
          ・職務経歴書
          <br />
          ・ポートフォリオ
          <br />
          ・関わったプロジェクトの提案書
        </p>
        <div className="flex flex-col my-10 mb-16 text-left">
          <div className="mt-0">
            <div className="font-bold text-sm block text-left">
              <p className="mb-2">ファイルから抽出</p>
              <Button
                className="btn-base btn-primary-base text-xs rounded-lg px-3 py-2 inline-flex items-center gap-1"
                onClick={fileSelect}
              >
                <span>ファイルをアップロード</span>
                <svg
                  className="w-3.5 h-3.5 fill-current text-white"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99992 1.66602L10.0974 1.67185C10.2834 1.69379 10.4566 1.77775 10.5891 1.91019C10.7215 2.04263 10.8055 2.21583 10.8274 2.40185L10.8333 2.49935V5.83268L10.8374 5.95768C10.8672 6.35449 11.038 6.72754 11.319 7.00937C11.5999 7.29121 11.9724 7.46323 12.3691 7.49435L12.4999 7.49935H15.8333L15.9308 7.50518C16.1168 7.52712 16.29 7.61108 16.4224 7.74352C16.5549 7.87597 16.6388 8.04917 16.6608 8.23518L16.6666 8.33268V15.8327C16.6666 16.4704 16.423 17.0839 15.9855 17.5479C15.548 18.0119 14.9498 18.2911 14.3133 18.3285L14.1666 18.3327H5.83325C5.19558 18.3327 4.58199 18.0891 4.11803 17.6516C3.65408 17.2141 3.37483 16.6159 3.33742 15.9793L3.33325 15.8327V4.16602C3.33322 3.52834 3.57686 2.91475 4.01432 2.4508C4.45179 1.98684 5.05001 1.70759 5.68658 1.67018L5.83325 1.66602H9.99992Z"
                    fill="#ffffff"
                  />
                  <path
                    d="M15.8333 5.83268H12.4999L12.4991 2.49852L15.8333 5.83268Z"
                    fill="#ffffff"
                  />
                </svg>
              </Button>
              <input
                type="file"
                id="file"
                accept="text/plain,.pdf,.docx,.xlsx,.pptx"
                onChange={handleFileSelect}
                className="hidden"
              />
              <span className="ml-2">{fileName}</span>
              <p className="mt-2 text-gray-500 font-normal text-sm">
                128MBまでのpdfまたはdocsファイルをアップロード可能
                <br />※ アップロードされたファイルはparks上には保存されません。
              </p>
            </div>
          </div>

          <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-6">
            <SecondaryButton className="w-32" onClick={closeModal}>
              キャンセル
            </SecondaryButton>
            <PrimaryButton
              className="w-44"
              onClick={extract}
              disabled={isFileNameEmpty}
            >
              キューブを抽出する
            </PrimaryButton>
          </div>
        </div>
      </ModalBase>
    )
  }

  // キューブ抽出中
  const createCubeLoadingModal = (cancelButtonDisabled: boolean) => {
    return (
      <ModalBase title="キューブを自動抽出" closeModal={closeModal}>
        <Loading className="loading" type="spin" color="#007559" />
        {!cancelButtonDisabled && (
          <div className="flex justify-center mt-10">
            <Button
              className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
              onClick={closeModal}
            >
              キャンセル
            </Button>
          </div>
        )}
      </ModalBase>
    )
  }

  // キューブ抽出完了
  const createCubeExtractedModal = () => {
    // キューブをチェックしたときの処理
    const checkCube = (cube: CubeQuery) => {
      if (checkedCubes.some(t => t.cubeName === cube.cubeName)) {
        const newCheckedCubes = checkedCubes.filter(
          t => t.cubeName !== cube.cubeName,
        )
        setCheckedCubes(newCheckedCubes)
      } else {
        setCheckedCubes([...checkedCubes, cube])
      }
    }

    // キューブ登録
    const createCubes = () => {
      if (checkedCubes.length === 0) return
      setCubeExtractStatus('creating')
      const memberCubeForms = checkedCubes.map(cube => {
        return {
          memberId: memberId,
          cubeName: cube.cubeName,
          cubeCategoryId: 3, // パーソナリティ固定
        } as MemberCubeForm
      })
      MemberCubeControllerService.createMemberCubes({
        memberCubes: memberCubeForms,
      } as MemberCubesForm)
        .then(() => {
          closeModal()
          toast.success('キューブを登録しました')
        })
        .catch(err => {
          toast.error('登録に失敗しました')
          apiErrorHandler(err)
        })
    }

    return (
      <ModalBase title="キューブを自動抽出" closeModal={closeModal}>
        <div className="mt-6 mb-16">
          <p className="text-gray-500 text-sm text-left">
            抽出結果からキューブを選択してください。選択されたキューブはパーソナリティのカテゴリーに追加されます
          </p>
          <div className="text-sm text-left">
            {extractedCubes.map((cube, index) => (
              <p
                className={`relative mt-2.5 pl-10 ${index === 0 ? 'mt-0' : ''}`}
                key={index}
              >
                <input
                  type="checkbox"
                  id={`management-${index}`}
                  name={`management-${index}`}
                  className="absolute opacity-0"
                  checked={checkedCubes.some(
                    target => target.cubeName === cube.cubeName,
                  )}
                  onChange={() => checkCube(cube)}
                />
                <label
                  htmlFor={`management-${index}`}
                  className="inline-block p-1.5 pl-3 pr-3 border border-sky-200 rounded-lg bg-[#e8f1fe] font-bold cursor-pointer relative leading-[23px]"
                >
                  <span
                    className={`absolute top-1.5 -left-9 w-[18px] h-[18px] border border-gray-200 rounded flex justify-center items-center ${
                      checkedCubes.some(
                        target => target.cubeName === cube.cubeName,
                      )
                        ? 'bg-green'
                        : 'bg-white'
                    }`}
                  >
                    {checkedCubes.some(
                      target => target.cubeName === cube.cubeName,
                    ) && (
                      <svg
                        className="w-3.5 h-2.5"
                        viewBox="0 0 14 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 5L5 9L13 1"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </span>
                  {cube.cubeName}
                </label>
              </p>
            ))}
          </div>

          <div className="flex justify-center absolute right-0 bottom-0 left-0 gap-2 mt-24 mb-4 bg-white">
            <SecondaryButton className="w-32" onClick={closeModal}>
              キャンセル
            </SecondaryButton>
            <PrimaryButton className="w-44" onClick={createCubes}>
              キューブを追加する
            </PrimaryButton>
          </div>
        </div>
      </ModalBase>
    )
  }

  switch (cubeExtractStatus) {
    case 'extracting':
      return createCubeLoadingModal(false)
    case 'extracted':
      return createCubeExtractedModal()
    case 'creating':
      return createCubeLoadingModal(true)
    default:
      return createCubeExtractModal()
  }
}
