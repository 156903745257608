import { FC } from 'react'
import { Head } from '../../layouts/Head'
import { Header } from '../../layouts/Header'
import { Footer } from '../../layouts/Footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import { TimelineControllerService } from '../../services'
import { Button } from '../../components/button'

export const Complete: FC = () => {
  const navigate = useNavigate()
  const addTimelineJoin = () => {
    // 参加通知を登録するが結果を判定しない
    TimelineControllerService.createTimelineJoin().finally(() => navigate('/'))
    // ボタンを無効化
    document.querySelector('.btn--sp-wide')?.setAttribute('disabled', 'true')
  }
  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main main__complete">
        <div className="form__complete">
          <h1 className="pagetitle">登録が完了しました🎉</h1>

          <p className="complete__text">
            お疲れ様でした！
            <br />
            さっそくparksを使ってみましょう！
            {/* TODO オンボーディング追加後に表示 */}
            {/*
            <br />
            次にparksの使い方を学びましょう
            */}
          </p>
          <div className="form">
            <p className="form__submit">
              <Button className="btn btn--sp-wide" onClick={addTimelineJoin}>
                ホームへ
              </Button>
            </p>
            {/* TODO オンボーディング追加後にボタン切り分け */}
            {/*
            <p className="form__submit">
              <button type="button" className="btn btn--sp-wide">
                使い方をみる
              </button>
            </p>
            <p className="form__submit--harf">
              <button
                type="button"
                className="btn btn__secondary btn__secondary-skip"
              >
                スキップ
              </button>
            </p>
            */}
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
